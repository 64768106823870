<div class="header w-100 d-flex align-items-center justify-content-center">
    <!-- <img src ="../../../../assets/images/WEB-DECORAZZIA-SECCIÓN-FLORERÍA.jpg"> -->
    <h3 class="letter-style">
      POLITICA DE TRATAMIENTO DE DATOS
    </h3>
</div>
<div class="row w-100 d-flex align-items-center justify-content-center">
    <div class="col-8 m-5">
        
    <p>
        <b>DERECHO DE RETRACTO:</b> De conformidad con lo estipulado por la legislación colombiana
        el consumidor podrá ejercer su derecho a retractarse de la compra de bienes que por su
        naturaleza no deban consumirse; el plazo para ejercer el retracto será de 5 días hábiles
        contados a partir de la entrega del bien. En el evento en que se haga uso de la facultad de
        retracto, se resolverá el contrato y se reintegrará la totalidad del dinero pagado por el
        consumidor.<br>
        El consumidor deberá devolver el producto a  <b>DECORAZZIA</b>  por los mismos medios y en las
        mismas condiciones en que lo recibió, esto es con sus empaques originales, piezas y
        accesorios. Los costos de transporte y los demás que conlleve la devolución del bien serán
        cubiertos por el consumidor.<br><br>
        <b>PROCEDIMIENTO PARA EL EJERCICIO DEL DERECHO DE RETRACTO:</b>  Para el ejercicio del
        derecho de retracto el consumidor deberá de seguir los siguientes pasos:
        1. Manifestar su voluntad de retractarse dentro de los 5 días hábiles siguientes a la
        recepción del producto, esta notificación deberá de hacerse por medio de la
        línea de atención de whatsapp: 301469 4747 o mediante el correo electrónico
        decorazzia@gmail.com, indicando nombre completo, número de contacto y
        producto adquirido. El consumidor puede adjuntar una imagen de la constancia
        de la compra.<br>
        2. Una vez recibida la notificación se le indicará por el mismo medio la dirección
        para el envío del producto a  <b>DECORAZZIA</b> , el consumidor deberá de realizar el
        envío durante los dos días hábiles siguientes.
        El consumidor deberá de embalar los productos de forma que se pueda asegurar
        la integridad de los mismos durante el transporte. Los costos de transporte
        deberán de ser cubiertos por el consumidor.
        3. Una vez recibido el producto y verificado el estado del mismo se hará la
        devolución del dinero pagado por el producto dentro de los siguientes 10 días
        hábiles.<br><br>
        <b>CAMBIO DEL PRODUCTO:</b> Los clientes podrán cambiar los artículos adquiridos en
        <b>DECORAZZIA</b> , para el cambio deberá devolver el producto a  <b>DECORAZZIA</b>  por los mismos
        medios y en las mismas condiciones en que lo recibió, esto es con sus empaques originales,
        piezas y accesorios. Los costos de transporte y los demás que conlleve el cambio del bien
        del bien serán cubiertos por el consumidor.<br><br>
        <b>PROCEDIMIENTO PARA EL CAMBIO DEL PRODUCTO:</b> Para el ejercicio del derecho de
        retracto el consumidor deberá de seguir los siguientes pasos:<br>
        1. Manifestar su voluntad de realizar el cambio del bien otro que se encuentre
        disponible en el e-commerce dentro de los 10 días hábiles siguientes a la
        recepción del producto, esta notificación deberá de hacerse por medio de la
        línea de atención de whatsapp: 301469 4747 o mediante el correo electrónico 
        decorazzia@gmail.com, indicando nombre completo, número de contacto,
        producto adquirido y producto por el cual desea realizar el cambio. El
        consumidor puede adjuntar una imagen de la constancia de la compra.<br>
        2. Una vez recibida la notificación se le indicará por el mismo medio la dirección
        para el envío del producto a  <b>DECORAZZIA</b> , el consumidor deberá de realizar el
        envío durante los dos días hábiles siguientes.
        El consumidor deberá de embalar los productos de forma que se pueda asegurar
        la integridad de los mismos durante el transporte. Los costos de transporte
        deberán de ser cubiertos por el consumidor.<br>
        3. Una vez recibido el producto y verificado su estado se realizará el envío del
        nuevo producto.<br><br>

        <b>GARANTIA:</b>  Todos los productos comercializados por  <b>DECORAZZIA</b>  cuentan con 2 meses de
        garantía por defectos de fabricación.<br>
        Los siguientes casos no serán cubiertos por la garantía del producto:<br>
        1. Fuerza mayor o caso fortuito.<br>
        2. El hecho de un tercero, en este numeral se incluyen los daños causados por
        la transportadora durante el transporte del producto, por lo que el cliente
        deberá de revisar el producto al momento de la recepción, y en caso no
        poderlo hacer deberá de poner en la guía de entrega mercancía sin verificar.<br>
        3. El uso indebido del bien por parte del consumidor.<br>
        4. Que el consumidor no atendió las instrucciones de instalación, uso o
        mantenimiento indicadas en el manual del producto y en la garantía.<br><br>
        <b>PROCEDIMIENTO DE GARANTIA: </b>  Para solicitar la garantía el consumidor deberá de seguir
        los siguientes pasos:<br>
        1. Solicitar la garantía del producto dentro de los dos meses siguientes a la recepción
        del producto, esta notificación deberá de hacerse por medio de la línea de atención
        de whatsapp: 301 469 4747 o mediante el correo electrónico
        decorazzia@gmail.com.<br>
        2. Una vez recibida la notificación se le indicará por el mismo medio la dirección para
        el envío del producto a  <b>DECORAZZIA</b> , el consumidor deberá de realizar el envío
        durante los dos días hábiles siguientes.<br>
        El consumidor deberá de embalar los productos de forma que se pueda asegurar la
        integridad de los mismos durante el transporte. Los costos de transporte serán
        cubiertos por  <b>DECORAZZIA</b> .<br>
        3. Una vez recibido el producto  <b>DECORAZZIA</b>  revisará el producto con el fin de la
        verificación del defecto de fabricación; en caso de que se encuentre que no es
        procedente la garantía se hará la devolución del producto indicándole al cliente las
        razones por las cuales no procede la garantía. <br>
        4. Se realizará la reparación del bien y posterior envío al cliente dentro de los 10 días
        hábiles siguientes al recibo del producto objeto de la garantía.<br>
        5. En caso de que no se pueda realizar la reparación del bien se hará el cambio por un
        bien con las mismas características, y en caso de que no se tuviere existencia y no
        se pudiere concretar el cambio por otro bien, se realizará la devolución del dinero. 


    </p>


    </div>
    
</div>

