 <div class="container">
        <div class="col-lg-5 imagen">
            <img class="logoDecorazzia" src="assets\images\Mesa de trabajo 24.png" alt="logo">
        </div>
        <div class="col-lg-5 mensaje">
            <p class="title">Oops!</p>
            <h3>No podemos encontrar la página que estás buscando.</h3>
            <br>
            <h6>Error code: 404</h6>
            <hr>
            <h6>Puedes volver a la <a routerLink="/home">página de inicio</a> o ponerte en <a routerLink="/contact">contacto con nosotros.</a></h6>
        </div>
</div>