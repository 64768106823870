import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Product } from '../models/products';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  private carCountSource = new BehaviorSubject<number>(0)
  public  carCount = this.carCountSource.asObservable();

  private subTotalSource = new BehaviorSubject<number>(0)
  public  subTotal = this.subTotalSource.asObservable();

  private totalSource = new BehaviorSubject<number>(0)
  public  total = this.totalSource.asObservable();

  private heightNavbar = new BehaviorSubject<any>(0)
  public  containNavbar = this.heightNavbar.asObservable();

  private totalItemsCar = new BehaviorSubject<any>(0);
  public totalCar = this.totalItemsCar.asObservable();

  private departmentSubjet = new BehaviorSubject<String>("");
  public department = this.departmentSubjet.asObservable();

  constructor() { }

  public updateCarCountVariable(carCountService){  
      this.carCountSource.next(carCountService);    
  }

  public updatedepartmentVariable(departmentService){  
    this.departmentSubjet.next(departmentService);    
  }

  public updatesubTotalVariable(subTotalService){  
    this.subTotalSource.next(subTotalService);    
  }

  public updatetotalVariable(totalService){  
    this.totalSource.next(totalService);    
  }

  public updateHeightNavbar(heightNavbar) {  
    this.heightNavbar.next(heightNavbar);    
  }

  public getTotalItemsCar(totalItemsCar) {
    this.totalItemsCar.next(totalItemsCar);
  }

}