<div class="container">    
    <div class="row-lg-12 mt-2">
        <h4 class="letter-style">
           {{message}}
        </h4>
    </div>
    <div class="row-lg-12 mt-5">
        <div class="col">
            <div class="row">
                <div class="col">
                    <h6>CÓDIGO DE TRANSACCIÓN:</h6>
                </div>
                <div class="col">
                    <h6>{{transaction.referenceCode}}</h6>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h6>CLIENTE:</h6>
                </div>
                <div class="col">
                    <h6>{{transaction.customer}}</h6>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h6>DESCRIPCIÓN:</h6>
                </div>
                <div class="col">
                    <h6>{{transaction.description}}</h6>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h6>VALOR:</h6>
                </div>
                <div class="col">
                    <h6>{{transaction.amount | currency : '$ '}}</h6>
                </div>
            </div>
        </div>         
    </div>   
    <div class="row mt-4">
        <button class="btn-view"  [disabled] ="!confirmed"  (click) = "redirect('/home')" >
            <span class="material-icons">
                SEGUIR COMPRANDO
            </span>
        </button>  
    </div>
</div>
<!-- <app-popular-products></app-popular-products>
 -->