import { Injectable } from "@angular/core";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class CognitoService {
  userPool: any;
  cognitoUser: any;
  username: string = "";
  password: string = "";
  forbiden: any[] = [
    "/login",
    "/confirmar-cuenta",
    "/nueva-password",
    "/resetear-password",
  ];
  constructor(private router: Router) {}

  signOut() {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };
    this.userPool = new CognitoUserPool(poolData);
    let cognitoUser = this.userPool.getCurrentUser();
    cognitoUser.signOut()
    this.router.navigate(["/home"]);
  }

  navigate() {
    let context = atob(localStorage.getItem("context"));
    let navigateTo = "/home";
    if (context.includes("http://localhost:4200/")) {
      navigateTo = context.split("http://localhost:4200/")[1];
    } else if (context.includes(environment.env)) {
      navigateTo = context.split(environment.env)[1];
    }
    if (this.forbiden.includes(navigateTo)) {
      navigateTo = "/home";
    }
    this.router.navigate([navigateTo]);
  }
  // Login
  login(emailaddress: any, password: any) {
    let authenticationDetails = new AuthenticationDetails({
      Username: emailaddress,
      Password: password,
    });

    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };

    this.username = emailaddress;
    this.userPool = new CognitoUserPool(poolData);
    let userData = { Username: emailaddress, Pool: this.userPool };
    this.cognitoUser = new CognitoUser(userData);

    this.cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result: any) => {
        this.router.navigate(["/home"]);
      },
      onFailure: (error: any) => {
        if (error.code === "UserNotConfirmedException") {
          this.router.navigate(["/confirmar-cuenta"]);
        }
        alert("Error iniciando sesión");
      },
    });
  }

  loginConfirm() {
    let authenticationDetails = new AuthenticationDetails({
      Username: this.username,
      Password: this.password,
    });

    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };

    this.password = "";
    this.userPool = new CognitoUserPool(poolData);
    let userData = { Username: this.username, Pool: this.userPool };
    this.cognitoUser = new CognitoUser(userData);

    this.cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result: any) => {
        this.router.navigate(["/home"]);
      },
      onFailure: (error: any) => {
        if (error.code === "UserNotConfirmedException") {
          this.router.navigate(["/confirmar-cuenta"]);
        }
      },
    });
  }

  signUp(data) {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };

    this.userPool = new CognitoUserPool(poolData);
    let attributeList = [];
    let dataEmail = {
      Name: "email",
      Value: data.username,
    };
    this.username = data.username;
    this.password = data.password;

    let attributeEmail = new CognitoUserAttribute(dataEmail);

    let birthdate = {
      Name: "birthdate",
      Value: data.birthdate,
    };
    let attributeBirthdate = new CognitoUserAttribute(birthdate);

    let family_name = {
      Name: "family_name",
      Value: data.family_name,
    };
    let attributefamily_name = new CognitoUserAttribute(family_name);

    let gender = {
      Name: "gender",
      Value: data.gender,
    };
    let atributegender = new CognitoUserAttribute(gender);

    let given_name = {
      Name: "given_name",
      Value: data.given_name,
    };
    let attributegiven_name = new CognitoUserAttribute(given_name);

    let phone_number = {
      Name: "phone_number",
      Value: data.phone_number,
    };
    let attributephone_number = new CognitoUserAttribute(phone_number);

    attributeList.push(attributeEmail);
    attributeList.push(attributefamily_name);
    attributeList.push(attributeBirthdate);
    attributeList.push(atributegender);
    attributeList.push(attributegiven_name);
    attributeList.push(attributephone_number);

    this.userPool.signUp(
      data.username.split("@")[0],
      data.password,
      attributeList,
      null,
      (err, result) => {
        if (err) {
          alert("Error registrando usuario");
          return;
        }
        var cognitoUser = result.user;
        this.router.navigate(["/confirmar-cuenta"]);
      }
    );
  }

  getExpiryTime() {
    let userData = "";
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };
    this.userPool = new CognitoUserPool(poolData);
    let cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser != null) {
      userData = cognitoUser.userDataKey.split(".userData")[0];
    }
    return localStorage.getItem(`${userData}.accessToken`)
      ? jwtDecode(localStorage.getItem(`${userData}.accessToken`)).exp
      : null;
  }

  public getId() {
    let userData = "";
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };
    this.userPool = new CognitoUserPool(poolData);
    let cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser != null) {
      userData = cognitoUser.userDataKey.split(".userData")[0];
    }
    return localStorage.getItem(`${userData}.idToken`)
      ? jwtDecode(localStorage.getItem(`${userData}.idToken`))
      : null;
  }

  isTokenExpired(): boolean {
    const expiryTime: number = this.getExpiryTime();
    if (expiryTime) {
      var d = new Date(expiryTime * 1000); // The 0 there is the key, which sets the date to the epoch
      return d.getTime() - new Date().getTime() < 5000;
    } else {
      return true;
    }
  }

  confirmAccount(code) {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };
    this.userPool = new CognitoUserPool(poolData);
    let userData = { Username: this.username, Pool: this.userPool };
    this.cognitoUser = new CognitoUser(userData);
    this.cognitoUser.confirmRegistration(null, true, function (err, result) {
      if (err) {
        alert("Error confirmando usuario");
        return;
      }
    });
  }

  resendConfirmationEmail() {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };
    this.userPool = new CognitoUserPool(poolData);
    let userData = { Username: this.username, Pool: this.userPool };
    this.cognitoUser = new CognitoUser(userData);
    this.cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        alert("Error enviando link de confirmación de usuario");
        return;
      }
    });
  }

  forgotPassword(data) {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };
    this.userPool = new CognitoUserPool(poolData);
    this.username = data.username;
    let userData = { Username: data.username, Pool: this.userPool };
    this.cognitoUser = new CognitoUser(userData);
    this.cognitoUser.forgotPassword({
      onSuccess: (data) => {
        // successfully initiated reset password request
        this.router.navigate(["/nueva-password"]);
      },
      onFailure: function (err) {
        if(err)
          alert("Error iniciando proceso de recuperación de contraseña");
      },
    });
  }

  newPassword(data) {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };
    this.userPool = new CognitoUserPool(poolData);
    this.username = data.username;
    let userData = { Username: this.username, Pool: this.userPool };
    this.cognitoUser = new CognitoUser(userData);
    var verificationCode = data.code;
    var newPassword = data.password;
    this.cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: (data) => {
        this.login(this.username, newPassword);
      },
      onFailure(err) {
        if(err)
          alert("Error cambiando contraseña");
          this.router.navigate(["/resetear-password"]);
      },
    });
  }
}
