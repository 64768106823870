import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Product } from 'src/app/shared/models/products';
import { ShopService } from 'src/app/shared/services/shop.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-compras',
  templateUrl: './compras.component.html',
  styleUrls: ['./compras.component.scss']
})
export class ComprasComponent implements OnInit {


  public listProducts;
  public message="";
  public urlS3=environment.urlBucketS3;
  public showMessage:boolean=false;
  public checkoutCarList:any=[];
  public itemFormGroup:FormGroup;
  public resumeGroup : FormGroup;
  public total:number=0;
  public arrayForm:FormArray;
  public productsNoStockname:any=[];
  constructor(private formBuilder:FormBuilder, private shop:ShopService, private router:Router) { 
    // localStorage.setItem('carList', JSON.stringify(this.products))
    // this.listProducts = this.products;
    this.initFormGroupControl();
    this.checkProductAvailability();

    
  }

  

  ngOnInit(): void {
  }

  public initFormGroupControl(){
    this.itemFormGroup = this.formBuilder.group({
      items: this.formBuilder.array([])
    })
    this.resumeGroup = this.formBuilder.group({
      resumenSubtotal:[''],
      resumenDelivery:[''],
      resumenTotal:['']
    })

    if(localStorage.getItem('carList')){
      if ( JSON.parse(localStorage.getItem('carList')).length >0) {
        this.checkoutCarList =JSON.parse(localStorage.getItem('carList'));        
        this.checkoutCarList.forEach(element => {          
          this.addItem(element);
          this.total=this.total+(parseFloat(element.count) * parseFloat(element.price))          
        });  
        this.resumeGroup = this.formBuilder.group({
          resumenSubtotal:[],
          resumenDelivery:[],
          resumenTotal:[ this.total   ,[Validators.required]]
        })
      }
    }
  }

  public checkProductAvailability(){    
    this.checkoutCarList.length>0?this.shop.checkProductAvailability({items: this.checkoutCarList}).subscribe(response=>{
      if (response.body['success']) {
        return true;
      }else{
        response.body['products'].forEach(productNoStock => {
          this.checkoutCarList.forEach(element => {
            if (productNoStock.ref === element.sk) {
 
              this.productsNoStockname.push(element.description)
            }
          });
        });
        this.showMessage=true;
        this.message=`Los siguientes productos están agotados: ${this.productsNoStockname.join(', ') }`       
      }      
    }):true//this.router.navigate(['/home']);
  }


  public addItem(itemCart){
    const items = this.itemFormGroup['controls'].items as FormArray;
    items.push(this.createItem(itemCart));
  }

  public createItem(itemCart){
    return this.formBuilder.group({
      itemReference: [itemCart.reference],
      itemImage: [itemCart.image],
      itemPrice:[itemCart.price ],
      itemCount : [itemCart.count],
      itemDescription : [itemCart.details],
      itemTittle : [itemCart.description],
      itemView : [itemCart.view],
      itemSubtotal:[ parseFloat(itemCart.price) * parseFloat(itemCart.count)]
    })
  }

  public updateCar(){
    localStorage.setItem("carList",JSON.stringify(this.checkoutCarList))
  }

  public removeItem(item)
  {
    const control = <FormArray> this.itemFormGroup.controls.items
    control.removeAt(item)
    this.checkoutCarList.splice(item,1)
    this.updateCar()
    // this.updateVariable.updateCarCountVariable(this.checkoutCarList.length)
    // this.recalculateTotal()
  }

  public changeSubTotal(formGroupSelected){
    if (Number.isNaN(parseFloat(formGroupSelected.value['itemCount'])) || parseFloat(formGroupSelected.value['itemCount'])<0) {
      formGroupSelected.controls['itemCount'].setValue(1)
      this.checkoutCarList.find(item=>{
        if(item.reference == formGroupSelected.value['itemReference']){
         item.count=formGroupSelected.value['itemCount']
         localStorage.setItem("carList",JSON.stringify(this.checkoutCarList))
        }
     }) 
    //  this.recalculateTotal();
    }else{
      formGroupSelected.controls['itemSubtotal'].setValue( parseFloat(formGroupSelected.value['itemPrice'])* parseFloat(formGroupSelected.value['itemCount']))
      this.checkoutCarList.find(item=>{
        if(item.reference == formGroupSelected.value['itemReference']){
         item.count=formGroupSelected.value['itemCount']
         localStorage.setItem("carList",JSON.stringify(this.checkoutCarList))
        }
     }) 
    //  this.recalculateTotal();
    }
  }

  
}
