import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { NavBarComponent } from "./components/nav-bar/nav-bar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { MenuComponent } from "./components/menu/menu.component";
import { CardsComponent } from "./components/cards/cards.component";
import { CardsCategoryComponent } from "./components/cards-category/cards-category.component";
import { SesionFloreriaComponent } from "./components/sesion-floreria/sesion-floreria.component";
import { SesionRelojeriaComponent } from "./components/sesion-relojeria/sesion-relojeria.component";
import { SesionNavidadComponent } from "./components/sesion-navidad/sesion-navidad.component";
import { PopularProductsComponent } from "./components/popular-products/popular-products.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "../app-routing.module";
import { SubMenuComponent } from "./sub-menu/sub-menu.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { CallbackComponent } from "./components/callback/callback.component";
import { LoginComponent } from "./components/login/login.component";
import { SignupComponent } from "./components/signup/signup.component";
import { ConfirmSignupComponent } from "./components/confirm-signup/confirm-signup.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { ForgetPasswordComponent } from "./components/forget-password/forget-password.component";
import { NewPasswordComponent } from "./components/new-password/new-password.component";
import { TopHeaderComponent } from "./top-header/top-header.component";
import { PopUpComponent } from "./components/pop-up/pop-up.component";
import { ModuleComponent } from './components/module/module.component';
import { ServicesComponent } from './components/services/services.component';
import { ProductsComponent } from './components/products/products.component';
import { LikedProductsComponent } from './components/liked-products/liked-products.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ServiceDetailComponent } from './components/service-detail/service-detail.component';
import { NewProductsComponent } from './components/new-products/new-products.component';
import { PopularCategoriesComponent } from "./components/popular-categories/popular-categories.component";
import { ReviewsComponent } from './components/reviews/reviews.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { CasePipe } from './case.pipe';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
  ],
  declarations: [
    NavBarComponent,
    FooterComponent,
    MenuComponent,
    CardsComponent,
    CardsCategoryComponent,
    SesionFloreriaComponent,
    SesionRelojeriaComponent,
    SesionNavidadComponent,
    PopularProductsComponent,
    SubMenuComponent,
    CarouselComponent,
    CallbackComponent,
    LoginComponent,
    SignupComponent,
    ConfirmSignupComponent,
    ResetPasswordComponent,
    SpinnerComponent,
    ForgetPasswordComponent,
    NewPasswordComponent,
    TopHeaderComponent,
    PopUpComponent,
    ModuleComponent,
    ServicesComponent,
    ProductsComponent,
    LikedProductsComponent,
    UserProfileComponent,
    ServiceDetailComponent,
    NewProductsComponent,
    PopularCategoriesComponent,
    ReviewsComponent,
    SubscriptionComponent,
    CasePipe
  ],
  exports: [
    MenuComponent,
    NavBarComponent,
    CardsComponent,
    CardsCategoryComponent,
    SesionFloreriaComponent,
    SesionRelojeriaComponent,
    SesionNavidadComponent,
    FooterComponent,
    PopularProductsComponent,
    SubMenuComponent,
    CarouselComponent,
    PopUpComponent,
    PopularCategoriesComponent,
    ReviewsComponent,
    SubscriptionComponent
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
