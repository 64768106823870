<div class="style letter-style w-100 d-flex align-items-center justify-content-center text-uppercase">
  <div class="row p-5 w-100 d-flex align-items-center justify-content-center">
    <div class="row w-100 m-5">
      <span class="contacto">INFORMACIÓN DE ENVÍO</span>
    </div>
    <div class="row d-flex w-100 align-items-center justify-content-center">
      <div class="card-body">
        <form [formGroup]="userPaymentInfo">

          <div class="form-row">
            <div class=" col-md-6 mt-3">
              <label for="typeperson" class="font-weight-bold">TIPO DE PERSONA</label>
              <small class="text-danger">*</small>
              <select id="typeperson" class="w-100" formControlName="person">
                <option selected>Selecciona...</option>
                <option>
                  PERSONA NATURAL
                </option>
                <option>
                  PERSONA JURÍDICA
                </option>
              </select>
            </div>
            <div class=" col-md-6 mt-3" *ngIf="userPaymentInfo.value.person == 'juridica'">
              <label for="inputEmail4" class="font-weight-bold">EMPRESA</label>
              <small class="text-danger">*</small>
              <input type="text" placeholder="Empresa" formControlName="enterprise" />
            </div>

            <div class=" col-md-6 mt-3" *ngIf="userPaymentInfo.value.person == 'natural'">
              <label for="inputEmail4" class="font-weight-bold">NOMBRE</label>
              <small class="text-danger">*</small>
              <input type="text" placeholder="Nombre" formControlName="name" />
            </div>
            <div class=" col-md-6 mt-3" *ngIf="userPaymentInfo.value.person == 'natural'">
              <label for="inputEmail4" class="font-weight-bold">APELLIDO</label>
              <small class="text-danger">*</small>
              <input type="text" placeholder="Apellido" formControlName="lastName" />
            </div>
            <div class=" col-md-6 mt-3">
              <label for="inputEmail4" class="font-weight-bold">CELULAR</label>
              <small class="text-danger">*</small>
              <input placeholder="Celular" formControlName="cellphone" />
              <small class="text-danger" *ngIf="
                  userPaymentInfo['controls'].cellphone.status == 'INVALID' &&
                  userPaymentInfo['controls'].cellphone.pristine == false
                ">*Celular inválido</small>
            </div>
            <div class=" col-md-6 mt-3">
              <label for="inputEmail4" class="font-weight-bold">EMAIL</label>
              <small class="text-danger">*</small>
              <input type="email" id="inputEmail4" placeholder="Correo Electrónico" formControlName="email" />
              <small class="text-danger" *ngIf="
                  userPaymentInfo['controls'].email.status == 'INVALID' &&
                  userPaymentInfo['controls'].email.pristine == false
                ">*Correo electrónico inválido</small>
            </div>
            <div class=" col-md-6 mt-3">
              <label for="typedocument" class="font-weight-bold">TIPO DE DOCUMENTO</label>
              <small class="text-danger">*</small>
              <select id="typedocument" class="w-100" formControlName="docType">
                <option selected>Selecciona...</option>
                <option *ngIf="userPaymentInfo.value.person != 'natural'">
                  NIT
                </option>
                <option>Cédula de Ciudadania</option>
                <option *ngIf="userPaymentInfo.value.person == 'natural'">
                  Cedula de extranjería
                </option>
                <option *ngIf="userPaymentInfo.value.person == 'natural'">
                  Pasaporte
                </option>
              </select>
            </div>
            <div class=" col-md-6 mt-2">
              <label class="font-weight-bold">DOCUMENTO</label>
              <small class="text-danger">*</small>
              <input type="number" placeholder="Documento" formControlName="document" />
            </div>
          </div>
          <div class=" mt-3">
            <label class="font-weight-bold">DIRECCIÓN</label>
            <small class="text-danger">*</small>
            <input type="text" id="inputAddress" placeholder="Dirección Principal" formControlName="address" />
          </div>
          <div class=" mt-3">
            <label class="font-weight-bold">DATOS ADICIONALES</label>
            <input type="text" id="inputAddress2" placeholder="Unidad, apartamento, casa"
              formControlName="additionalAddress" />
          </div>
          <div class="form-row">
            <div class=" col-md-6 mt-3">
              <label for="inputCity" class="font-weight-bold">DEPARTAMENTO</label>
              <small class="text-danger">*</small>
              <select id="inputCity" class="w-100" formControlName="city" (change)="getMunicipality()">
                <option selected></option>
                <option *ngFor="let dep of department">{{ dep }}</option>
              </select>
            </div>
            <div class=" col-md-6 mt-3">
              <label for="inputState" class="font-weight-bold">CIUDAD</label>
              <small class="text-danger">*</small>
              <select id="inputState" class="w-100" formControlName="state">
                <option selected></option>
                <option *ngFor="let dep of municipality">{{ dep }}</option>
              </select>
            </div>
          </div>
          <div class=" col-md-8 mt-3"  >
            <div class="d-flex w-100 mt-3">
              <input type="checkbox" class="checkbox" value="" id="flexCheckDefault" (change)="changeValidStatus()" [disabled]="userPaymentInfo.invalid"/>
              <label class="checkbox-label ml-3" for="flexCheckDefault">
                HE LEÍDO DE MANERA CLARA Y EXPRESAMENTE ACEPTO Y AUTORIZO LOS <a
                  href="https://decorazzia-documents.s3.amazonaws.com/TERMINOS+Y+CONDICIONES+DECORAZZIA.pdf"
                  target="_blank">TÉRMINOS Y CONDICIONES</a>,
                <a href="https://decorazzia-documents.s3.amazonaws.com/POL%C3%8DTICA+DE+TRATAMIENTO+DE+DATOS+PERSONALES+DECORAZZIA.pdf"
                  target="_blank">
                  POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</a>
              </label>
            </div>
          </div>
        </form>
        <form #form ngNoForm id="payuForm" method="post" action="{{ urlPayU }}">
          <input name="merchantId" type="hidden" value="{{ merchantId }}" />
          <input name="accountId" type="hidden" value="{{ accountId }}" />
          <input name="description" type="hidden" value="Compra realizada a Decorazzia" />
          <input name="referenceCode" type="hidden" value="{{ referenceCode }}" />
          <input name="amount" type="hidden" value="{{ this.totalPaymentStr }}" />
          <input name="tax" type="hidden" value="{{ tax }}" />
          <input name="taxReturnBase" type="hidden" value="{{ taxReturnBase }}" />
          <input name="currency" type="hidden" value="{{ currency }}" />
          <input name="signature" type="hidden" value="{{ signature }}" />
          <input name="test" type="hidden" value="{{ environment }}" />
          <input name="buyerEmail" type="hidden" value="{{ this.buyerEmail }}" />
          <input name="responseUrl" type="hidden" value="{{ responseUrl }}" />
          <input name="confirmationUrl" type="hidden" value="{{ confirmationUrl }}" />
          <input name="paymentMethods" type="hidden" value="{{ this.paymentMathods }}" />
          <input name="extra2" type="hidden" value="{{ this.buyercc }}" />
        </form>
        <div class=" col-md-8">
          <!-- <label *ngIf="showDeliveryMessage">{{deliveryMessage}}{{ delivaryAmount | currency : ''}}</label>      -->
          <!-- <label *ngIf="showPayonDestiny">{{deliveryMessage}}</label>       
                                                           -->
          <label *ngIf="this.cuponDiscount > 0">*TU CUPÓN DE DESCUENTO POR SUSCRIPCIÓN SE APLICÓ AUTOMATICAMENTE</label>

        </div>
        <button class="btn-view" 
          (click)="createTransaction()">
          PAGAR {{ this.totalPaymentShow | currency : "" }}
        </button>
      </div>
    </div>
  </div>
</div>