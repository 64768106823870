import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'case'
})
export class CasePipe implements PipeTransform {

  transform(value: string): unknown {
    let str = value.trim()
    return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
  }

}