import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements OnInit, OnChanges {
  @Input() slides;
  filterSlides: any[] = [];
  innerWidth: any;
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (window.innerWidth < 460)
      this.slides = changes["slides"]["currentValue"].filter(
        (res) => res.mobile
      );
    else
      this.slides = changes["slides"]["currentValue"].filter(
        (res) => !res.mobile
      );
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }
}
