<div class="row w-100 m-0 main-banner">
  <div class="col w-100 m-0 p-0">
    <div>
      <app-carousel [slides]="slides" class="w-100"></app-carousel>
    </div>
  </div>
</div>
<div class="row w-100 divider m-0 p-0"></div>
<div class="row d-flex justify-content-center m-0 p-0">
  <div class="divider-icon-div d-flex justify-content-center">
    <img src="assets\icons\downarrow.png">
  </div>
</div>

<div class="row d-flex justify-content-center m-0 p-0">
  
   <div class="col-12 mt-5 d-flex justify-content-center">
    <h4 class="head-tittle">NUESTROS PRODUCTOS</h4>
  </div>
  <div class="col-12 mt-5 d-flex justify-content-center text-sm-center">
    <h5 class="regular-tittle ">Transformamos espacios en experiencias únicas.</h5>
  </div>
  <div class="col-12 mt-2 d-flex justify-content-center">
    <!-- <button class="button-style" *ngFor="let item of this.categoriesList; let i = index" (click)="redirectSubcategories(item.father,item['category'])">
      {{item['category']}}
    </button> -->
    <!-- <button class="button-style"  (click)="redirectSubcategories(this.categoriesList[0].father,this.categoriesList[0]['category'])">
      {{this.categoriesList[0]['category']}}
    </button> -->
  </div>
  <div class="col-7 divider-cards mt-5 mb-5">

  </div>
</div>
<app-cards></app-cards>

<div class="row w-100 m-0 subcription-div">
  <div class="col w-100 m-0 p-0 ">
    <div name="carrousel-container">
      <app-subscription class="w-100"></app-subscription>
    </div>
  </div>
</div>
<div class="row w-100 divider-agenda-first m-0 p-0">
  <div class="col-lg-4 col-sm-12 m-0 p-0 d-flex justify-content-center">
    <div class="col-4 m-0 p-0 mr-2 mt-4 d-flex justify-content-end">
      <img src="assets\icons\u.png">
    </div>
    <div class="col m-0 p-0 mt-3">
      <span>SHOWROOM<br>HORARIO NAVIDAD</span>
    </div>
  </div>
  <div class="col-sm-12 col-lg-4 d-none d-md-flex justify-content-center">
    <div class="col-4 m-0 p-0 mr-2 mt-4 d-flex justify-content-end">
      <img src="assets\icons\t.png">
    </div>
    <div class="col m-0 p-0 mt-4">
      <span>HORARIO GENERAL</span>
    </div>
  </div>
  <div class="col-sm-12 col-lg-4 m-0 p-0 d-none d-md-flex justify-content-center">
    <div class="col-6 m-0 p-0 mr-2 mt-4 d-flex justify-content-end">
      <img src="assets\icons\headset.png">
    </div>
    <div class="col m-0 p-0 mt-4">
      <span>ATENCIÓN ONLINE</span>
    </div>
  </div>
</div>
<div class="row w-100 divider-agenda span-agenda m-0 p-0">
  <div class="col-lg-4 col-sm-12 m-0 p-0 d-flex justify-content-center">
    <div class="col-4 m-0 p-0 mr-2">
    </div>
    <div class="col m-0 p-0 mt-3 d-flex justify-content-start">
      <span class="span-agenda">LUNES A DOMINGO <br>DE 9:00 AM A 7:00 PM</span>
    </div>
  </div>
  <div class="col-sm-12 col-lg-4 d-none d-md-flex justify-content-center">
    <div class="col-4 m-0 p-0 mr-2">
    </div>
    <div class="col m-0 p-0 mt-4 d-flex justify-content-start">
      <span class="span-agenda">DE 8:30 AM A 6:00 PM</span>      
    </div>
    <!-- <div class="row m-0 p-0 mt-4 d-flex justify-content-center">
      <span>*DOMINGOS Y FERIADOS NO LABORAMOS</span>
    </div> -->
  </div>
  <div class="col-sm-12 col-lg-4 m-0 p-0 d-none d-md-flex justify-content-center">
    <div class="col-6 m-0 p-0 mr-2">
    </div>
    <div class="col m-0 p-0 mt-4 d-flex justify-content-start">
      <span class="span-agenda">LUNES A SÁBADO <br>DE 9:00 AM A 7:00 PM</span>
    </div>
  </div>
</div>
