import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Contact } from 'src/app/shared/models/contact'
@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private url = environment.url;

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' ,
     'x-api-key': environment.apikey 
    }),
    observe: 'response' as 'response',
  };

  constructor(private http: HttpClient) { }

  public sendEmail(contact: Contact){
    return this.http.post( `${this.url}contacto`, contact, this.httpOptions);
  }

  public getServices(){
    return this.http.post( `${this.url}services`, this.httpOptions);
  }

}
