<div class="container h-50 mt-5">
  <div class="col">
    <div class="row d-flex justify-content-center">
      <span class="contacto">ACCEDER</span>
    </div>
    <div class="row d-flex justify-content-center p-3">
      <label
        >HEMOS ENVIADO UN MAIL AL SU CORREO REGISTRADO, VERIFICA EN SU BANDEJA DE ENTRADA Y SIGUE LAS INSTRUCCIONES PARA VERIFICAR TU CUENTA. UNA VEZ TERMINES LAS INSTRUCCIONES, HAZ CLIC EN  <strong>CONTINUAR</strong>.
      </label>
    </div>
    <div class="row d-flex justify-content-center pl-5 p-3">
      <span *ngIf="showLink">NO RECIBISTE EL LINK? <a class="navbar__link w-100" style="cursor: pointer;" >ENVIAR NUEVAMENTE</a></span>
    </div>
    <!-- <div class="row d-flex justify-content-center">
      <div id="inputs" class="inputs">
        <input id="input1" class="input" type="text" inputmode="numeric" maxlength="1"  (input)="onSearchChange($event)" (keyup)="onKeyUp($event)"/>
        <input id="input2" class="input" type="text" inputmode="numeric" maxlength="1"  (input)="onSearchChange($event)"  (keyup)="onKeyUp($event)"/>
        <input id="input3" class="input" type="text" inputmode="numeric" maxlength="1"  (input)="onSearchChange($event)" (keyup)="onKeyUp($event)" />
        <input id="input4" class="input" type="text" inputmode="numeric" maxlength="1"   (input)="onSearchChange($event)" (keyup)="onKeyUp($event)"/>
        <input id="input5" class="input" type="text" inputmode="numeric" maxlength="1"   (input)="onSearchChange($event)" (keyup)="onKeyUp($event)"/>
        <input id="input6" class="input" type="text" inputmode="numeric" maxlength="1"   (input)="onSearchChange($event)" (keyup)="onKeyUp($event)"/>
      </div>      
    </div> -->
    <div class="row mt-4 d-flex justify-content-center">
      <button type="submit" class="button-style" (click)="login()" [disabled]="dissable">
        CONTINUAR
      </button>
    </div>
  </div>
</div>

