import { Component, OnInit } from '@angular/core';
import { CognitoService } from '../../services/cognito.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  user: any={}
  constructor(private auth: CognitoService) { }

  ngOnInit(): void {
    this.user= this.auth.getId()
  }

  signOut(){
    this.auth.signOut();
  }
}
