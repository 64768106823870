<div class="headerFloreria d-flex align-items-center justify-content-center">
  <img class="w-100" src="{{s3banner}}/banners/regular/4.png" *ngIf="tittle=='CANDELABROS'">
  <img class="w-100" src="{{s3banner}}/banners/regular/5.png" *ngIf="tittle=='RELOJES'">
  <img class="w-100" src="{{s3banner}}/banners/regular/3.png" *ngIf="tittle=='BANDEJAS'">
  <img class="w-100" src="{{s3banner}}/banners/regular/6.png" *ngIf="tittle=='ESPEJOS'">
  <img class="w-100" src="{{s3banner}}/banners/regular/7.png" *ngIf="tittle=='LÁMPARAS'">
  <img class="w-100" src="{{s3banner}}/banners/regular/12.png" *ngIf="tittle=='ACCESORIOS'">
  <img class="w-100" src="{{s3banner}}/banners/regular/9.png" *ngIf="tittle=='FLOREROS'">
  <img class="w-100" src="{{s3banner}}/banners/regular/8.png" *ngIf="tittle=='APLIQUES'">
  <img class="w-100" src="{{s3banner}}/banners/regular/11.png" *ngIf="tittle=='ESCULTURAS'">
  <img class="w-100" src="{{s3banner}}/banners/regular/2.png" *ngIf="tittle=='FLORES ARTIFICIALES'">
  <img class="w-100" src="{{s3banner}}/banners/regular/13.png" *ngIf="tittle=='BOWLS'">
  <img class="w-100" src="{{s3banner}}/banners/regular/10.png" *ngIf="tittle=='ÁNFORAS'">
  <img class="w-100" src="{{s3banner}}/banners/regular/14.png" *ngIf="tittle=='PORTARETRATOS'">
  <!-- <img class="w-100" src="{{s3banner}}/banners/regular/13.png" *ngIf="tittle=='COJINES'"> -->
  <img class="w-100" src="{{s3banner}}/banners/2.png" *ngIf="tittle=='MUÑECOS'">
  <img class="w-100" src="{{s3banner}}/banners/1.png" *ngIf="tittle=='FLORES NAVIDEÑAS'">
  <img class="w-100" src="{{s3banner}}/banners/7.png" *ngIf="tittle=='ÁRBOLES'">
  <img class="w-100" src="{{s3banner}}/banners/4.png" *ngIf="tittle=='FOLLAJE NAVIDEÑO'">
  <img class="w-100" src="{{s3banner}}/banners/5.png" *ngIf="tittle=='LUCES NAVIDEÑAS'">
  <img class="w-100" src="{{s3banner}}/banners/6.png" *ngIf="tittle=='ACCESORIOS NAVIDEÑOS'">
  <img class="w-100" src="{{s3banner}}/banners/3.png" *ngIf="tittle=='NACIMIENTOS'">
  </div>
  <app-cards></app-cards>
