
<div class="w-100  d-flex  align-items-center justify-content-center " >
  <div class="row">
    <div class="row w-100 m-5">
      <div class="col-12">
        <h3 class="contacto">Contacto</h3>
      </div>
    </div>
    <div class="row w-100 m-5">
      <div class="col-12">
        <form [formGroup]= "formContact">
          <div class="form-group">
            <input formControlName="name" placeholder="NOMBRE Y APELLIDO"  class="form-control" type="text" name="name" required/>
          </div>
          <div class="form-group">
            <input formControlName="email" placeholder="CORREO" class="form-control" type="email" name="email"  required/>
          </div>
          <div class="form-group">
              <input formControlName="cellphone" placeholder="TELEFONO" class="form-control" type="number" name="phone"  required/>
            </div>
          <div class="form-group" >
            <textarea formControlName="message" rows="4" cols="40" placeholder="MENSAJE" class="form-control" name="message" required></textarea>
          </div>
          <div class="form-group" *ngIf="showMessage">  
            <small class="alert-success">
              {{message}}
            </small>
          </div>
          <div class="form-group" *ngIf="showError">  
            <small class="alert-warning">
              {{message}}
            </small>
          </div>
        </form>
        <div class="show-more-div w-80">
          <button (click)="onSubmit()" class="btn" [disabled]="!formContact.valid">ENVIAR</button>
        </div>
      </div>      
    </div>
  </div>    
</div>

