import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private url = environment.url;

  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "x-api-key": environment.apikey,
    }),


  }
  constructor(private http: HttpClient) { }


  putSubscription(data) {
    return this.http.post(`${this.url}suscripcion `, data, this.httpOptions);
  }

  
  getSubscription(data) {
    return this.http.get(`${this.url}suscripcion?email=${data}`,  this.httpOptions);
  }


}
