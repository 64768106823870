<div class="container mt-4 h-75">
  <div class="row mt-5 mb-5">
    <div class="col">
      <h3 class="contacto">{{ serviceGot.title }}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card-columns">
        <div class="card" *ngFor="let ser of this.modulesList">
          <img class="card-img-top" [src]="ser.url" alt="Card image cap" />
          <div class="card-body">
            <h5 class="card-title">{{ ser.title }}</h5>
            <p class="card-text">
              {{ ser.description }}
            </p>
            <a class="a-design" href="{{ serviceMessage }}" target="_blank">
              Solicitar información
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
