import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private url = environment.url;

  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "x-api-key": environment.apikey,
    }),


  }
  constructor(private http: HttpClient) {}

  getCategories(){
    return this.http.get(`${this.url}categorias`, this.httpOptions); 
  }

}