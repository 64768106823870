import { Component, HostListener, OnInit } from '@angular/core';
import { CardsService } from '../../services/cards.service';
import { VariablesService } from '../../services/variables.service';

@Component({
  selector: 'app-sesion-floreria',
  templateUrl: './sesion-floreria.component.html',
  styleUrls: ['./sesion-floreria.component.scss']
})
export class SesionFloreriaComponent implements OnInit {

  public innerWidth: any =  window.innerWidth;
  hide:boolean = true;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth>768)
      this.hide = true;
    else  
      this.hide=false;
  }

  constructor(private cardsService:CardsService) {

    this.cardsService.getProducts()
    .subscribe(resp => {
      if(resp.body['success']){        
        cardsService.updateCardProducts(resp.body['body'].filter(prod=>prod.category=='Flores'))
      }  
    });
  }

  ngOnInit(): void {
    this.onResize()
  }

}
