import { Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { Product } from "../../models/products";
import { CardsService } from "../../services/cards.service";
import { CatalogService } from "../../services/catalog.service";
import { VariablesService } from "../../services/variables.service";
import { CognitoService } from "../../services/cognito.service";

@Component({
  selector: "app-popular-products",
  templateUrl: "./popular-products.component.html",
  styleUrls: ["./popular-products.component.scss"],
})
export class PopularProductsComponent implements OnInit {
  public carList: any = [];
  public listProducts: any = [];
  public urlS3 = environment.urlBucketS3;
  public selectedItem: Product = new Product();
  public itemFormGroup: FormGroup;
  public innerWidth: any;
  public itemsCount: number = 4;
  public totalCar;
  public increment: number = 1;
  index = 4;
  page = 0;
  likes: any[] = [];
  showLarge = false;
  showMid = false;
  showSm = false;
  constructor(
    private products: CatalogService,
    private formBuilder: FormBuilder,
    private cardsService: CardsService,
    private router: Router,
    private variableService: VariablesService,
    private toastr: ToastrService,
    private auth: CognitoService
  ) {
    if (localStorage.getItem("carList") !== null) {
      this.carList = JSON.parse(localStorage.getItem("carList"));
    }
    this.itemFormGroup = this.formBuilder.group({
      count: ["1"],
    });
    this.variableService.totalCar.subscribe((res) => {
      this.totalCar = res;
    });
  }

  ngOnInit(): void {
    this.onResize();
    this.getPopularProducts(this.page);
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth < 1028 && this.innerWidth > 765) {
      this.showMid = true;
      this.showSm = this.showLarge = false;
    } else if (this.innerWidth >= 1028) {
      this.showLarge = true;
      this.showSm = this.showMid = false;
    } else if( this.innerWidth <= 765){
      this.showSm = true;
      this.showLarge = this.showMid = false;
    } 
    this.getPopularProducts(0);
  }

  public getPopularProducts(page) {
    this.products.getCatalog().subscribe((response) => {
      if (response.body["success"]) {
        this.listProducts = response.body["body"].filter(
          (prod) => prod.deal.active
        ).sort( () => .5 - Math.random() );
        this.listProducts = this.chunkIntoN(this.listProducts, 4)
      }
    });
  }

  chunkIntoN(arr, n) {
    const size = Math.ceil(arr.length / n);
    return Array.from({ length: n }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  }

  likeProduct(product: Product) {
    if(this.auth.getId()){
      this.likes.push(product.sk);
      this.products
        .postLike(this.auth.getId()["email"], this.likes)
        .subscribe((res) => {
          this.listProducts = this.listProducts.map((item) => {
            if (item.sk === product.sk) return { ...item, liked: true };
            else return item;
          });
        });
    } else this.router.navigate(['/login'])
  }

  unlikeProduct(product: Product) {
    this.likes = this.likes.filter((item) => item !== product.sk);
    this.products
      .postLike(this.auth.getId()["email"], this.likes)
      .subscribe((res) => {
        this.listProducts = this.listProducts.map((item) => {
          if (item.sk === product.sk) return { ...item, liked: false };
          else return item;
        });
      });
  }

  public onView(product: Product) {
    this.cardsService.updateProductDetail(product);
    this.router.navigate([`/detalles/${product.sk}`]);
    this.products.postView(product).subscribe();
  }

  public updateCar(product: Product) {
    this.carList = JSON.parse(localStorage.getItem("carList"));
    let arrayAux: Product[] = [];
    let found: boolean = false;
    if (this.carList && this.carList.length > 0) {
      this.carList.forEach((item) => {
        if (item.sk === product.sk) {
          arrayAux.push(product);
          this.toastr.success("Producto actualizado.", "", {
            timeOut: 1500,
          });
          found = true;
        } else {
          arrayAux.push(item);
        }
      });
      if (!found) {
        arrayAux.push(product);
        this.variableService.getTotalItemsCar(this.totalCar + this.increment);
        this.toastr.success("Se agregó el producto a la bolsa.", "", {
          timeOut: 1500,
        });
      }
    } else {
      this.variableService.getTotalItemsCar(this.totalCar + this.increment);
      this.toastr.success("Se agregó el producto a la bolsa.", "", {
        timeOut: 1500,
      });
      arrayAux.push(product);
    }
    localStorage.setItem("carList", JSON.stringify(arrayAux));
    localStorage.setItem("carrito", JSON.stringify(arrayAux.length));
  }

  public addToCar(product: Product) {
    product.count = +this.updateCar(product);
  }
}
