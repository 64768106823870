import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { CognitoService } from "../../services/cognito.service";

@Component({
  selector: "app-new-password",
  templateUrl: "./new-password.component.html",
  styleUrls: ["./new-password.component.scss"],
})
export class NewPasswordComponent implements OnInit {
  signInForm: FormGroup;
  username:any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private cognito: CognitoService
  ) {
    this.username=sessionStorage.getItem("username");
    this.signInForm = this.formBuilder.group(
      {
        password: ["", [Validators.required, Validators.minLength(8)]],
        confirmpassword: ["", [Validators.required, Validators.minLength(8)]],
        code: ["", [Validators.required, Validators.minLength(6)]],
        username:[this.username=sessionStorage.getItem("username")]
      },
      { validators: this.customPasswordMatching.bind(this) }
    );
  }

  customPasswordMatching(control: AbstractControl): ValidationErrors | null {
    const password = control.get("password")?.value;
    const confirmPassword = control.get("confirmpassword")?.value;
    return password === confirmPassword
      ? null
      : { passwordMismatchError: true };
  }

  forgotPassword() {
    this.cognito.newPassword(this.signInForm.getRawValue());
  }

  ngOnInit(): void {}
}
