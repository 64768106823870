<div class="container-fluid h-50 text-uppercase">
  <div class="row d-flex justify-content-center">
    <div class="col-lg-4 col-md-12 mt-5">
      <span>HEMOS ENVIADO UN CÓDIGO DE VERIFICACIÓN A TU CORREO ELECTRONICO, INGRESALO JUNTO CON TU NUEVA CONTRASEÑA</span>
      <form [formGroup]="signInForm" (ngSubmit)="forgotPassword()">
        <div class=" mt-3">
          <label>CÓDIGO</label>
          <input type="email" formControlName="code" />
        </div>
        <div class="mt-3">
          <label>Contraseña</label>
          <input type="password" formControlName="password" />
        </div>
        <div class="mt-3">
          <label>CONFIRMAR CONTRASEÑA</label>
          <input type="password" formControlName="confirmpassword" autocomplete="new-password" />
        </div>
        <button type="submit" class="button-style mt-3" [disabled]="signInForm.invalid">
          CAMBIAR CONTRASEÑA
        </button>
      </form>
    </div>
  </div>
</div>