import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { SharedModule } from "./shared/shared.module";
import { ContactComponent } from "./screens/contact/contact.component";
import { PaymentInformationComponent } from "./screens/payment-information/payment-information.component";
import { HomeComponent } from "./screens/home/home.component";
import { HttpClientModule } from "@angular/common/http";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ComprasComponent } from "./screens/compras/compras.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PagenotfoundComponent } from "./screens/pagenotfound/pagenotfound.component";
import { CardDetailComponent } from "./shared/components/card-detail/card-detail.component";
import { MisComprasComponent } from "./screens/mis-compras/mis-compras.component";
import { PaymentcompletedpageComponent } from "./screens/paymentcompletedpage/paymentcompletedpage.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { TermsConditionsComponent } from "./screens/terms-conditions/terms-conditions.component";
import { ReturnsExchangesComponent } from "./screens/returns-exchanges/returns-exchanges.component";
import { InformationTreartmentComponent } from "./screens/information-treartment/information-treartment.component";
import { SubcategoryComponent } from "./shared/components/subcategory/subcategory.component";

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    PaymentInformationComponent,
    HomeComponent,
    ComprasComponent,
    PagenotfoundComponent,
    CardDetailComponent,
    MisComprasComponent,
    PaymentcompletedpageComponent,
    TermsConditionsComponent,
    ReturnsExchangesComponent,
    InformationTreartmentComponent,
    SubcategoryComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
