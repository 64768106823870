
<div class="container__compras container_page w-100">
    <div class= "container">
        <div class="row card-row margin-top" >
            <div class="w-100%" >
                <form [formGroup]="itemFormGroup">
                    <div  formArrayName="items" *ngFor="let item of this.itemFormGroup.controls.items['controls']; let i = index">
                        <form class="column-display margin-top" [formGroupName]="i">
                            <div>
                                <img  class="imag-card" src="{{urlS3}}{{item.value.itemImage[0]}}" >
                            </div>                    
                            <div class="card-body-display">
                                <div class="card-tittle">
                                    <span class="font-weight-bold">{{item.value.itemTittle}}</span>                       
                                </div>
                                <div class="card-description">                            
                                    <p>
                                        {{item.value.itemDescription}}                       
                                    </p>
                                </div> 
                                <div class="card-actions">
                                    <ul class="list-unstyled list-inline font-small">
                                        <li class="list-inline-item pr-2">
                                            <p  class="card-views">{{item.value.itemView}}   
                                                <svg width="1em" name="views" height="1em" viewBox="0 0 16 16" class="bi bi-eye" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"/>
                                                    <path fill-rule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                                </svg>
                                            </p> 
                                        </li>
                                        <li class="list-inline-item pr-2">
                                            <!-- <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-heart" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                            </svg> -->
                                        </li>
                                        <div class="list-inline-item pr-2" >
                                            <span  class="card-views">unidades 
                                                
                                                <input type="number" formControlName="itemCount" min="1" type="number"   >
                                                <small *ngIf="item.value['itemCount']<0" class="text-danger">*Valor inválido</small>
                                            </span>                                                                                                         
                                        </div>
                                        <div class="btn-div">
                                            <!-- <button  class="btn-view" data-toggle="modal" data-target="#exampleModal" >COMPRAR</button>
                                            <button  class="btn-view btn-left-margin" data-toggle="modal" data-target="#exampleModal" >SEGUIR COMPRANDO</button> -->
                                        </div>
                                    </ul>
                                </div>                       
                            </div>
                            <div class ="close-button"  (click)="removeItem(i)" >
                                <svg width="1.8em" height="1.8em" viewBox="0 0 16 16" class="bi bi-x-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </div>
                        </form>
                    </div> 
                </form>               
            </div>
        </div>         
    </div>
</div>
<!-- <app-popular-products></app-popular-products> 
 -->    
 