import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../services/services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  servicesList: any[]=[]
  constructor(private services: ServicesService,
    private router:Router
  ) {
    this.services.getServices().subscribe(res=>
      {
        this.servicesList = res['body']['services'].filter(res=>res.active)
      }
    )
   }
  ngOnInit(): void {

  }

  details(service){
    this.router.navigate(['servicios',service])
  }
}
