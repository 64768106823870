export class Product {
    price: number;
    category: string ='';
    count: number;
    deal: {
        active: false;
        offer: string;
    };
    description:string ='';
    details: string ='';
    fakePrice: number;
    hk: string='';
    image:any = [];
    like:number;
    sk:string = '';
    status:string='';
    stock:number;
    view:number;
    active:boolean;
}