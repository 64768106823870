<div class="container-fluid h-50 text-uppercase">
  <div class="row  d-flex justify-content-center">
    <div class="col-lg-4 col-md-12 mt-5 ">
      <span class="contacto">OLVIDASTE TU CONTRASEÑA?</span>
      <form [formGroup]="usernameForm" (ngSubmit)="forgotPassword()">
        <div class="mt-3">
          <label
            >INGRESA TU CORREO ELECTRÓNICO A CONTINUACIÓN PARA REINICIAR TU
            CONTRASEÑA</label
          >
          <input type="email" formControlName="username" />
        </div>
        <button
          type="submit"
          class="button-style mt-3"
          [disabled]="usernameForm.invalid"
        >
          CONTINUAR
        </button>
      </form>
    </div>
  </div>
</div>