<div class="header w-100 d-flex align-items-center justify-content-center">
    <!-- <img src ="../../../../assets/images/WEB-DECORAZZIA-SECCIÓN-FLORERÍA.jpg"> -->
    <h3 class="letter-style">
      POLITICA DE TRATAMIENTO DE DATOS
    </h3>
</div>
<div class="row w-100 d-flex align-items-center justify-content-center">
    <div class="col-8 m-5">
        <p>
            <b>ALCANCE:</b> Esta política aplica para toda la información personal registrada en las Bases de Datos de
 <b>DECORAZZIA</b>  de clientes, usuarios, trabajadores, proveedores y/o público en general.<br><br>
 <b>RESPONSABLE DEL TRATAMIENTO:</b> WILMER ZAPATA SANJUAN, identificado con cedula de
ciudadanía número 98700966, con domicilio en la calle 76 # 52D 112 del municipio de Itagüí
Antioquia, en adelante  <b>DECORAZZIA</b> con teléfono de contacto y correo electrónico:
decorazzia@gmail.com, será el responsable del tratamiento de los datos personales y las bases de
datos.<br><br>
<b>OBJETIVO:</b> Establecer los criterios para la recolección, almacenamiento, uso, circulación y supresión
de los datos personales, suministrados por los clientes, usuarios, trabajadores, proveedores y/o
público en general tratados por DECORAZZIA.<br><br>
<b>OBLIGACIONES:</b> Esta política es de obligatorio y estricto cumplimiento para  <b>DECORAZZIA</b> quien
actuará como el responsable de tratamiento.<br><br>
<b>DEFINICIONES:</b> Para efectos de la presente Política, se tendrán en cuenta las definiciones señaladas
en la Ley 1581 de 2012 tal como se transcriben a continuación:
<b>I. Autorización:</b>  Consentimiento previo, expreso e informado del Titular para llevar a cabo el
Tratamiento de datos personales.<br>
<b>II. Base de Datos:</b>  Conjunto organizado de datos personales que sea objeto de Tratamiento.<br>
<b>III. Dato Personal:</b>  Cualquier información vinculada o que pueda asociarse a una o varias
personas naturales determinadas o determinables.<br>
<b>IV. Dato Sensible:</b>  Son aquellos que afectan la intimidad del Titular y pueden dar lugar a que
sea discriminado, como aquellos que revelan su origen racial o étnico, su orientación
política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones
sociales, de derechos humanos, así como los datos relativos a la salud, a la vida sexual, y los
datos biométricos. Este tipo de datos constituyen una categoría especial de datos
personales y por ello, requieren una protección reforzada y algunas consideraciones
especiales a la hora de solicitar autorización para su tratamiento.<br>
<b>V. Encargado del Tratamiento:</b>  Persona natural o jurídica, pública o privada, que por sí misma
o en asocio con otros, realice el Tratamiento de datos personales por cuenta del
Responsable del Tratamiento.<br>
<b>VI. Políticas de Tratamiento en materia de Protección de Datos Personales:</b>  Se refiere al
presente documento.<br>
<b>VII. Responsable del Tratamiento:</b>  Persona natural o jurídica, pública o privada, que por sí
misma o en asocio con otros, decida sobre la Base de Datos y/o el Tratamiento de los datos.
Para el caso concreto DECORAZZIA.<br>
<b>VIII. Titular:</b>  Persona natural cuyos Datos Personales sean objeto de Tratamiento.<br>
<b>IX. Tratamiento:</b>  Cualquier operación o conjunto de operaciones sobre datos personales, tales
como la recolección, almacenamiento, uso, circulación o supresión.<br><br>
<b>TRATAMIENTO DE DATOS PERSONALES Y FINALIDADES:</b>  El tratamiento que realizará DECORAZZIA
con la información personalserá: la recolección, almacenamiento y uso para: (I)Realizar invitaciones
a eventos y ofrecer productos y servicios por medios electrónicos. (ii) Efectuar encuestas de
satisfacción respecto de los bienes ofrecidos por  <b>DECORAZZIA</b> (iii) Contactar al Titular a través de
medios telefónicos para realizar encuestas, estudios y/o confirmación de datos personales
necesarios para la ejecución de una relación contractual. (iv) Contactar al Titular a través de medios
electrónicos, SMS, chat, correo electrónico y redes sociales; para el envío de noticias relacionadas
con campañas de fidelización, descuentos especiales en productos, extractos, estados de cuenta o
facturas en relación con las obligaciones derivadas del contrato celebrado entre las partes. (vi) Dar
cumplimiento a las obligaciones contraídas por  <b>DECORAZZIA</b> con el Titular de la Información (vii)
Realizar ventas por medios electrónicos (viii) Dar cumplimiento a lo relacionado con el pago de
salarios, prestaciones sociales y demás retribuciones consagradas en el contrato de trabajo o según
lo disponga la ley.<br><br>
 <b>DERECHOS DE LOS TITULARES:</b>Como titular de sus datos personales Usted tiene derecho a: (i)
Acceder a los datos proporcionados que hayan sido objeto de tratamiento. (ii) Conocer, actualizar y
rectificar su información frente a datos parciales, inexactos, incompletos, fraccionados, que
induzcan a error, o aquellos cuyo tratamiento esté prohibido o no haya sido autorizado. (iii) Solicitar
prueba de la autorización otorgada. (iv) Presentar ante la Superintendencia de Industria y Comercio
(SIC) quejas por infracciones a lo dispuesto en la normatividad vigente. (v) Revocar la autorización
y/o solicitar la supresión del dato, siempre que no exista un deber legal o contractual que impida
eliminarlos. (vi) Abstenerse de responder las preguntas sobre datos sensibles. Tendrá carácter
facultativo las respuestas que versen sobre datos sensibles o sobre datos de las niñas y niños y
adolescentes.<br><br>

<b>DEBERES DEL RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES:</b> De conformidad con el
art. 17 de la Ley 1581 de 2012, el Responsable del Tratamiento tendrá los siguientes deberes:
(i)Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data. (ii)
Solicitar y conservar, en las condiciones previstas en la reglamentación aplicable, copia de la
respectiva autorización otorgada por el Titular. (iii)Informar debidamente al Titular sobre la finalidad
de la recolección y los derechos que le asisten por virtud de la autorización otorgada. (iv) Conservar
la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida,
consulta, uso o acceso no autorizado o fraudulento. (v) Garantizar que la información que se
suministre al Encargado del Tratamiento sea veraz, completa, exacta, actualizada, comprobable y
comprensible.(vi) Actualizar la información, comunicando de forma oportuna al Encargado del
Tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y
adoptar las demás medidas necesarias para que la información suministrada a éste se mantenga
actualizada (vii) Rectificar la información cuando sea incorrecta y comunicar lo pertinente al
Encargado del Tratamiento. (viii) Suministrar al Encargado del Tratamiento, según el caso,
únicamente datos cuyo Tratamiento esté previamente autorizado de conformidad con lo previsto
en la reglamentación aplicable. (ix) Exigir al Encargado del Tratamiento en todo momento, el respeto
a las condiciones de seguridad y privacidad de la información del Titular. (x)Tramitar las consultas y
reclamos formulados en los términos señalados en la reglamentación aplicable. (xi) Adoptar un
manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la 
reglamentación aplicable y en especial, para la atención de consultas y reclamos. (xii) Informar al
Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte
del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.
(xiii) Informar a solicitud del Titular sobre el uso dado a sus datos. (xiv) Informar a la autoridad de
protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en
la administración de la información de los Titulares. (xv) Cumplir las instrucciones y requerimientos
que imparta la Superintendencia de Industria y Comercio.<br><br>


<b>ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS:</b> el señor JAMES GIRALDO GIRALDO es la
persona que tiene a cargo dar trámite a las solicitudes de los titulares para hacer efectivos sus
derechos, las peticiones consultas y reclamos deberán de presentarse al correo electrónico
decorazzia@gmail.com o físicamente en la sede de  <b>DECORAZZIA</b> ubicada en la calle 76 # 52D 112.
PROCEDIMIENTO PARA EL EJERCICIO DEL DERECHO DE HABEAS DATA: En cumplimiento de las
normas sobre protección de datos personales,  <b>DECORAZZIA</b> presenta el procedimiento y requisitos
mínimos para el ejercicio de sus derechos: Para la radicación y atención de su solicitud le solicitamos
suministrar la siguiente información: (i) Nombre completo (ii) Datos de contacto (Dirección física
y/o electrónica y teléfonos de contacto) (iii) Medios para recibir respuesta a su solicitud (iv)
Motivo(s)/hecho(s) que dan lugar al reclamo con una breve descripción del derecho que desea
ejercer (conocer, actualizar, rectificar, solicitar prueba de la autorización otorgada, revocarla,
suprimir, acceder a la información) (v) Firma (vi) número de identificación.<br>
El término máximo previsto por la ley para resolver su reclamación es de quince (15) días hábiles,
contado a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo
dentro de dicho término,  <b>DECORAZZIA</b> informará al interesado los motivos de la demora y la fecha
en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles
siguientes al vencimiento del primer término. Una vez cumplidos los términos señalados por la Ley
1581 de 2012 y las demás normas que la reglamenten o complementen, el Titular al que se
deniegue, total o parcialmente, el ejercicio de los derechos de acceso, actualización, rectificación,
supresión y revocación, podrá poner su caso en conocimiento de la Superintendencia de Industria y
Comercio.<br><br>

<b>VIGENCIA:</b> La presente Política para el Tratamiento de Datos Personales rige a partir del 27 de
septiembre de 2020.<br>
Las bases de datos en las que se registrarán los datos personales tendrán una vigencia igual al
tiempo en que se mantenga y utilice la información para las finalidades descritas en esta política.
Una vez se cumpla(n) esa(s) finalidad(es) y siempre que no exista deber legal o contractual de
conservar su información, sus datos serán eliminados de nuestras bases de datos, así mismo cuando
medie solicitud de supresión por parte del titular.
    </p>

    </div>
    
</div>