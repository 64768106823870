<div class="d-flex justify-content-center" style="background-color: var(--color-beige) !important;">
  <a class="close-navbar-toggler collapsed" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"></a>
  <nav class="navbar navbar-expand-xl navbar-light bg-light w-100" style="position: relative; z-index: 1">
    <a class="navbar-brand" href="#"><img src="assets/images/d.png" alt="logo" class="navbar__img" /></a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="d-lg-none d-flex w-100">
      <div class="col-md-5">
        <form [formGroup]="searchGroup">
          <div>
            <input type="text" formControlName="criteria" />
          </div>
        </form>
      </div>
      <div class="col-md-7">
        <a class="badge-position  justify-content-end" [routerLink]="['/compras']">
          <img class="navbar__icons_carrito" src="assets\icons\2.png" alt="compras" />
          <span class="badge badge-color" *ngIf="totalItems > 0">{{
            totalItems
            }}</span>
        </a>
      </div>
    </div>
    <div class="collapse navbar-collapse justify-content-end p-3 mt-2" id="navbarSupportedContent">
      <ul class="navbar-nav">
       <!--  //(click)="redirectCategories(category.category)" -->
        <li class="nav-item dropdown" *ngFor="let category of categoriesList; let i = index" >
          <a class="nav-link dropdown-toggle"  id="navbarDropdown"
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ category.category }}
          </a>
          <div class="dropdown-menu" *ngIf="category['sub-categories']" aria-labelledby="navbarDropdown">
            <a class="dropdown-item " *ngFor="
                let subCategories of category['sub-categories'];
                let i = index
              " (click)="
                redirectSubcategories(category.category, subCategories.category)
              ">{{ subCategories.category | case}}</a>
          </div>
        </li>
        <li class="nav-item mr-2" (click)="routeTo('servicios')">
          <a class="nav-link" role="button">
            SERVICIOS
          </a>
          <!-- div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#" *ngFor="let service of servicesList; let i = index">{{ service.title }}</a>
          </div> -->
        </li>
        <!-- <li class="nav-item mt-2 mr-3">
          <a role="button" (click)="
          redirect('productos/nuevos')
        ">LO NUEVO</a>
        </li> -->
        <li class="nav-item mt-2 mr-3">
          <a role="button" (click)="redirectOut()" target="_blank">MAYORISTAS</a>
        </li>
      </ul>
    </div>
    <div class="row d-none d-lg-flex d-xl-flex m-0 p-0  ">
      <div class="col-xl-6 justify-content-end">
        <form [formGroup]="searchGroup">
          <div>
            <input type="text" formControlName="criteria" />
          </div>
        </form>
      </div>
      <div class="col-xl-6 d-flex mt-1 justify-content-end">
        <div class="col-1 col-xl-3 mr-2">
          <a class="badge-position" [routerLink]="['/compras']">
            <img class="navbar__icons_carrito" src="assets\icons\2.png" alt="compras" />
            <span class="badge badge-color" *ngIf="totalItems > 0">{{
              totalItems
              }}</span>
          </a>
        </div>
        <div class="col-1 col-xl-3  d-none d-sm-none d-md-none d-lg-block">
          <a href="https://www.facebook.com/Decorazzia-825801597539175/" target="_blank">
            <img class="navbar__icons_fb" src="assets/icons/face.png" alt="facebook" /></a>
        </div>
        <div class="col-1 col-xl-3  d-none d-sm-none d-md-none d-lg-block">
          <a href="https://www.instagram.com/decorazzia/" target="_blank">
            <img class="navbar__icons" src="assets/icons/c.png" alt="instagram" />
          </a>
        </div>
      <!--   <div class="col-1 col-xl-3 pr-3 d-none d-sm-none d-md-none d-lg-block">
          <a (click)="redirectLogin()" target="_blank">
            <img class="navbar__icons" src="assets\icons\account.png" alt="account" />
          </a>
        </div> -->
      </div>
      <!--     <div class="col-1">
        <a routerLink="productos/likes" class="navbar__icons"><svg xmlns="http://www.w3.org/2000/svg" height="25px"
            viewBox="0 -960 960 960" width="25px" fill="#000">
            <path
              d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
          </svg>
        </a>
      </div> -->
    </div>
  </nav>
</div>