import { Component, HostListener, OnInit } from '@angular/core';
import { CardsService } from '../../services/cards.service';
import { CatalogService } from '../../services/catalog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.scss']
})
export class SubcategoryComponent implements OnInit {
  listProducts:any[]=[];
  tittle:string='';
  itemsCount:number=4;
  public s3banner = environment.urlAssetsS3 
  public innerWidth: any =  window.innerWidth;
  hide:boolean = true;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth>768)
      this.hide = true;
    else  
      this.hide=false;
  }


  constructor(private card:CardsService) { }

  ngOnInit(): void {
    this.initListen();
    this.onResize()

  }

  initListen(){
    this.card.subcategory.subscribe(category=>{
      if(category){
        this.tittle = category;
        this.getPopularProducts()
      }      
    })
  }

  public getPopularProducts(){
    this.card.getProducts()
    .subscribe(resp => {
      if(resp.body['success']){        
        this.card.updateCardProducts(resp.body['body'].filter(prod=>prod.subcategory==this.tittle))
      }  
    });
  }

}