<footer>
    <div class="d-flex w-100">
        <div class="row w-100">
            <div class="col-lg-4 col-sm-12 m-0 p-0 mt-5 d-flex justify-content-center">
                <div class="row">
                    <div>
                        <span><strong>SHOWROOM</strong></span>
                        <span>Calle 76 # 52D - 112</span>
                        <span>ITAGÜÍ - ANTIOQUIA</span>
                        <span>WHATSAPP
                            <a class="ml-2"
                                href="https://api.whatsapp.com/send?phone=573014694747&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20productos."
                                target="_blank"> +57 301 469 4747</a>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-lg-4 m-0 p-0 d-flex justify-content-center">
                <img class="footer__img mt-5 mb-5" src="assets\icons\l.png" alt="logo">
            </div>
            <div class="col-sm-12 col-lg-4 m-0 p-0 mt-5 d-flex justify-content-center">
                <div clspanss="row">
                    <a href="/compras">CARRITO</a>
                    <a (click)="redirectLogin()" >MI CUENTA</a>
                    <a (click)="routeTo('servicios')" >NUESTROS SERVICIOS</a>            
                    <a href="">PREGUNTAS FRECUENTES</a>                   
       
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- <footer>
    <div class="container pt-5">
        <div class="row pl-5">
            <div class="col-sm-12 col-md-4 col-lg-4 h-100  mt-4">
                <div class="row pt-sm-1 pt-md-2 pt-lg-2">
                    <span>
                        AYUDA AL CLIENTE
                    </span>
                </div>
                <div class="row pt-sm-1 pt-md-3 pt-lg-3">
                    <a href="/terminos-condiciones"> Términos y condiciones</a>
                    <a href="/devoluciones-cambios"> Cambios y devoluciones</a>
                    <a href="/tratamiento-datos"> Política de tratamiento de datos</a>
                </div>

            </div>

            <div class="col-sm-12 col-md-4 col-lg-4 h-100 mt-4">
                <div class="row pt-sm-1 pt-md-2 pt-lg-2">
                    <span>
                        VENTAS AL MAYOR
                    </span>
                </div>
                <div class="row pt-sm-1 pt-md-3 pt-lg-3">
                    <a href="https://api.whatsapp.com/send?phone=573014694747&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20productos."
                        target="_blank"> Quieres vender Decorazzia</a>
                </div>

            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 h-100  mt-4">
                <div class="row  pt-sm-1 pt-md-2 pt-lg-2">
                    <span>
                        CONTACTO
                    </span>
                </div>
                <div class="row pt-sm-1 pt-md-3 pt-lg-3">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-1" style="justify-content: center;
                                align-items: center;
                                margin: 0;
                                padding: 0;
                                display: flex;
                                opacity: .4 ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-geo-alt" viewBox="0 0 16 16">
                                    <path
                                        d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                    <path d="M8 8a2 2  0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                </svg>
                            </div>
                            <div class="col-11" style="justify-content: initial;
                                align-items: center;
                                margin: 0;
                                padding: 0;
                                display: flex;">
                                <div class="row m-0"><a>Visitanos<br>Centro Comercial Gran Plaza. Piso 9. Local 905b -
                                        Medellin</a> </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-1" style="justify-content: center;
                                align-items: center;
                                margin: 0;
                                padding: 0;
                                display: flex;
                                opacity: .4 ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-geo-alt" viewBox="0 0 16 16">
                                    <path
                                        d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                    <path d="M8 8a2 2  0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                </svg>
                            </div>
                            <div class="col-11" style="justify-content: initial;
                                align-items: center;
                                margin: 0;
                                padding: 0;
                                display: flex;">
                                <div class="row m-0"><a>Show Room<br>Calle 76 N52 D 112 Itagüi</a> </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-1" style="justify-content: center;
                                align-items: center;
                                margin: 0;
                                padding: 0;
                                display: flex;
                                opacity: .4 ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-telephone" viewBox="0 0 16 16">
                                    <path
                                        d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                </svg>
                            </div>
                            <div class="col-11" style="justify-content: initial;
                                align-items: center;
                                margin: 0;
                                padding: 0;
                                display: flex;">
                                <div class="row m-0"><a>+57 301 469 4747</a></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-1" style="justify-content: center;
                                align-items: center;
                                margin: 0;
                                padding: 0;
                                display: flex;
                                opacity: .4 ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-envelope" viewBox="0 0 16 16">
                                    <path
                                        d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                                </svg>
                            </div>
                            <div class="col-11" style="justify-content: initial;
                                align-items: center;
                                margin: 0;
                                padding: 0;
                                display: flex;">
                                <div class="row m-0"><a>decorazzia@gmail.com</a> </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-4 h-100">
                <div class="col pl-5">
                    <ul>
                        <li>
                            <a class="navbar__link" [routerLink]="['/home']" [routerLinkActive]="['active']">INICIO</a>
                        </li>
                        <li>
                            <a class="navbar__link" [routerLink]="['/floreria']" [routerLinkActive]="['active']">FLORES
                                ARTIFICIALES</a>
                        </li>
                        <li>
                            <a class="navbar__link" [routerLink]="['/decoracion']"
                                [routerLinkActive]="['active']">DECORACIÓN</a>
                        </li>
                        <li>
                            <a class="navbar__link" [routerLink]="['/navidad']"
                                [routerLinkActive]="['active']">NAVIDAD</a>
                        </li>
                        <li>
                            <a class="navbar__link" target=”_blank”
                                href="https://api.whatsapp.com/send?phone=573014694747&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20productos.">CONTACTO</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 h-100"></div>
            <div class="col-sm-12 col-md-4 col-lg-4 h-100">
                <div class="row h-100">
                    <div class="col-6 div_imag">
                        <a href="https://www.facebook.com/Decorazzia-825801597539175/" target="_blank"><img
                                class="navbar__icons" src="assets/icons/facebook.png" alt="facebook"></a>
                        <a href="https://www.instagram.com/decorazzia/" target="_blank" class="social-intragram"><img
                                class="navbar__icons" src="assets/icons/instagram.png" alt="instagram"> </a>

                    </div>
                    <div class="col-6">
                        <img class="footer__img mb-5" src="assets/images/logo.png" alt="logo">
                    </div>

                </div>
            </div>

        </div>
    </div>
</footer> -->