<!-- <div class="container-fluid w-75 mb-5">
  <div class="row">
    <div class="col-lg-6 col-sm-12 mt-5">
      <span class="contacto">Acceder</span>
      <form [formGroup]="signInForm" (ngSubmit)="signIn()">
        <div >
          <label >Usuario</label>
          <small class="text-danger">*</small>
          <input type="text" class="form-control" formControlName="username" />
        </div>
        <div >
          <label >Contraseña</label>
          <small class="text-danger">*</small>
          <input type="password" class="form-control" formControlName="password" />
        </div>
        <div class="row p-3">
          <a class="navbar__link w-100" href="/resetear-password">Olvidaste tu contraseña?</a>
        </div>
        <button type="submit" class="button-style" [disabled]="signInForm.invalid">
          Ingresar
        </button>
      </form>
    </div>
    <div class="col-lg-6 col-sm-12 mt-5">
      <span class="contacto">Registrarse</span>
      <form [formGroup]="signUpForm" (ngSubmit)="signUp()">
        <div >
          <label >Email</label>
          <small class="text-danger">*</small>
          <input type="email" class="form-control" formControlName="username" />
        </div>
        <div >
          <label >Nombre</label>
          <small class="text-danger">*</small>
          <input type="text" class="form-control" formControlName="given_name" />
        </div>
        <div >
          <label >Apellido</label>
          <small class="text-danger">*</small>
          <input type="text" class="form-control" formControlName="family_name" />
        </div>
        <div >
          <label for="inputEmail4" >Género</label>
          <small class="text-danger">*</small>
          <select class="form-control" formControlName="gender">
            <option selected>Selecciona...</option>
            <option>Femenino</option>
            <option>Masculino</option>
          </select>
        </div>
        <div >
          <label >Celular</label>
          <small class="text-danger">*</small>
          <input type="tel" class="form-control" formControlName="phone_number" placeholder="Ejemplo: +573102531"
            name="celular" />
        </div>
        <div >
          <label >Fecha de nacimiento</label>
          <small class="text-danger">*</small>
          <input type="date" class="form-control" formControlName="birthdate" />
        </div>

        <div >
          <label >Contraseña</label>
          <small class="text-danger">*</small>
          <input type="password" class="form-control" formControlName="password" autocomplete="new-password" />
        </div>
        <div >
          <label >Requisitos de contraseña
          </label>
          <ul>
            <li>Al menos 8 caracteres</li>
            <li>Una letra minúscula</li>
            <li>Una letra mayúscula</li>
            <li>Un número</li>
            <li>Un símbolo</li>
          </ul>
        </div>
        <button type="submit" class="button-style" [disabled]="signUpForm.invalid">
          Registrarse
        </button>
      </form>
    </div>
  </div>
</div> -->

<div class="container-fluid w-75 mb-5 text-uppercase">
  <div class="row mt-4">
    <div class="col-lg-6 col-sm-12 mt-5">
      <span class="contacto">Acceder</span>
      <form [formGroup]="signInForm">
        <div class="mt-3">
          <label>Usuario</label>
          <small class="text-danger">*</small>
          <input type="email" formControlName="username" />
        </div>
        <div class="mt-3">
          <label>Contraseña</label>
          <small class="text-danger">*</small>
          <input type="password" formControlName="password" />
        </div>
        <div class="row p-3">
          <a class="navbar__link w-100" href="/resetear-password">Olvidaste tu contraseña?</a>
        </div>
        <button type="submit" class="button-style" [disabled]="signInForm.invalid" (click)="signIn()">
          Ingresar
        </button>
      </form>
    </div>
    <hr class="solid w-100 mt-5 d-lg-none d-md-none d-sm-block">
    <div class="col-lg-6 col-sm-12 mt-5">
      <span class="contacto">Registrarse</span>
      <form [formGroup]="signUpForm" (ngSubmit)="signUp()">
        <div class="mt-3">
          <label>Email</label>
          <small class="text-danger">*</small>
          <input type="email" class="form-control" formControlName="username" />
        </div>
        <div class="mt-3" >
          <label >Nombre</label>
          <small class="text-danger">*</small>
          <input type="text" class="form-control" formControlName="given_name" />
        </div>
        <div class="mt-3" >
          <label >Apellido</label>
          <small class="text-danger">*</small>
          <input type="text" class="form-control" formControlName="family_name" />
        </div>
        <div class="mt-3 dropdown" >
          <label for="inputEmail4" >Género</label>
          <small class="text-danger">*</small>
          <select class="form-control" formControlName="gender">
            <option class="option-s" selected>Selecciona...</option>
            <option>Femenino</option>
            <option>Masculino</option>
          </select>
        </div>
        <div class="mt-3">
          <label >Celular</label>
          <small class="text-danger">*</small>
          <input type="tel" class="form-control" formControlName="phone_number" placeholder="Ejemplo: +573102531"
            name="celular" />
        </div>
        <div class="mt-3">
          <label >Fecha de nacimiento</label>
          <small class="text-danger">*</small>
          <input type="date" class="form-control" formControlName="birthdate" />
        </div>
  
        <div class="mt-3">
          <label >Contraseña</label>
          <small class="text-danger">*</small>
          <input type="password" class="form-control" formControlName="password" autocomplete="new-password" />
        </div>
        <div class="mt-3">
          <label >Requisitos de contraseña
          </label>
          <ul>
            <li>Al menos 8 caracteres</li>
            <li>Una letra minúscula</li>
            <li>Una letra mayúscula</li>
            <li>Un número</li>
            <li>Un símbolo</li>
          </ul>
        </div>
        <button type="submit" class="button-style" [disabled]="signUpForm.invalid">
          Registrarse
        </button>
      </form>
    </div>
  </div>
</div>