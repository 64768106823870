import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CatalogService } from 'src/app/shared/services/catalog.service';
import { ShopService } from 'src/app/shared/services/shop.service';
import { VariablesService } from 'src/app/shared/services/variables.service';
import { jwtDecode } from "jwt-decode";
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';
import { v4 as uuidv4 } from 'uuid';
import { CognitoService } from 'src/app/shared/services/cognito.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss']
})
export class PaymentInformationComponent implements OnInit {

  public urlPayU: string = environment.payU.urlPay;
  public apiKey: string = environment.payU.apiKey;
  public merchantId: string = environment.payU.merchant;
  public accountId: string = environment.payU.account;
  public signature: string = '';
  public confirmationUrl: string = environment.payU.confirmationUrl;
  public responseUrl: string = environment.payU.responseUrl;
  public environment: string = environment.payU.environment;
  public currency: string = environment.payU.currency;
  public tax: string = environment.payU.tax;
  public validStatus: boolean = false;
  public paymentCreated: boolean = false;
  public taxReturnBase: string = environment.payU.taxReturnBase;
  public referenceCode: string = '';
  public buyerEmail: string = environment.payU.buyerEmail;
  public buyercc: string = "";

  public paymentMathods: string = environment.payU.paymentTypes;
  public userPaymentInfo: FormGroup;
  public url = environment.urlBucketS3;
  public products: [];
  public catalog: [];
  public department: [];
  public municipality: any;
  public totalPayment: number = 0;
  public totalPaymentShow: number = 0;

  public totalPaymentStr: string = '';
  public departmentSelected: string = '';
  public waitingResponse: boolean = false
  public metropolitanArray: String[] = ['Medellín', 'Itagui', 'Sabaneta', 'La Estrella'];
  public outtersMetropolitanArray: String[] = ['Bello'];
  public showDeliveryMessage: boolean = false;
  public deliveryMessage: string = '';
  public delivaryAmount: number = 0;
  public showPayonDestiny: boolean = false;
  cuponDiscount: number = 0;
  discountAmountCupon: number = 0;
  profile: any = {};

  @ViewChild('form') form!: ElementRef;

  constructor(private formBuilder: FormBuilder,
    private shop: ShopService,
    private catalogService: CatalogService,
    private router: Router,
    private variables: VariablesService,
    private auth: CognitoService,
    private sub: SubscriptionService

  ) {
    this.profile = this.auth.getId();


    this.variables.department.subscribe(department => {
      this.catalogService.getMunicipality(department).subscribe(res => {
        this.municipality = res;
      })
    })

    catalogService.getDepartments().subscribe(res => {
      this.department = res['list'];
    });

    this.shop.product.subscribe(products => {
      this.products = products.checkout
      this.catalog = products.products
      this.products.forEach(element => {
        this.totalPayment = this.totalPayment + element['itemSubtotal'];
        this.totalPaymentShow = this.totalPayment;
      });

      if(this.profile)
        sub.getSubscription(this.profile.email).subscribe(res => {
          if (res["body"] && res["body"]["enable"]) {
            this.cuponDiscount = 10;
            if (this.cuponDiscount > 0) {
              this.discountAmountCupon = (this.cuponDiscount / 100) * this.totalPayment
              this.totalPayment = this.totalPayment - this.discountAmountCupon
              this.totalPaymentShow = this.totalPayment
              this.totalPaymentStr = String(this.totalPayment);
            } else {
              this.totalPaymentStr = String(this.totalPayment);
            }
            this.generateSignature();
          }
        })
    })

    if (this.totalPayment === 0) {
      this.router.navigate(['/compras']);
    }
  }
  ngOnInit(): void {

    this.userPaymentInfo = this.formBuilder.group({
      person: ['natural'],
      enterprise: [''],
      document: ['', Validators.required],
      name: [this.profile ? this.profile.given_name : ""],
      lastName: [this.profile ? this.profile.family_name : ""],
      docType: [''],
      cellphone: [this.profile ? this.profile.family_name : "", Validators.required],
      email: [this.profile ? this.profile.email : "", [Validators.required, Validators.email]],
      address: ['', Validators.required],
      additionalAddress: [''],
      city: ['', Validators.required],
      state: ['', Validators.required]
    })


  }

  public generateSignature() {
    let md5 = new Md5();
    this.referenceCode = uuidv4().split('-')[0];
    let signature = md5.appendStr(`${this.apiKey}~${this.merchantId}~${this.referenceCode}~${this.totalPaymentStr}~${this.currency}~${this.paymentMathods}`).end()
    this.signature = signature.toString();
    localStorage.setItem('signature', this.signature);
    localStorage.setItem('referenceCode', this.referenceCode);
    return signature
  }

  public getMunicipality() {
    this.showDeliveryMessage = false;
    this.showPayonDestiny = false;
    this.deliveryMessage = ''
    this.variables.updatedepartmentVariable(this.userPaymentInfo.value.city);
  }


  changeValidStatus() {
    this.validStatus = !this.validStatus;
    this.buyerEmail = this.userPaymentInfo.value.email;
    this.buyercc = this.userPaymentInfo.value.document;
  }

  createTransaction() {
    if (this.validStatus) {
      let paymentInformation = {
        userInfor: this.userPaymentInfo.value,
        signature: this.signature,
        referenceCode: this.referenceCode,
        items: this.catalog
      }
      localStorage.setItem('paymentReference', paymentInformation.referenceCode);
      localStorage.setItem('document', paymentInformation.userInfor.document);
      localStorage.setItem('amount', this.totalPaymentStr);
      if (this.userPaymentInfo.value.enterprise != '') localStorage.setItem('customer', `${this.userPaymentInfo.value.enterprise}`);
      if (this.userPaymentInfo.value.name != '') localStorage.setItem('customer', `${this.userPaymentInfo.value.name} ${this.userPaymentInfo.value.lastName}`);
      this.shop.createPayment(paymentInformation).subscribe(response => {
        if (response['body']['success'])
          this.form.nativeElement.submit();
      });
    }
  }
}
