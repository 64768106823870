<div class="top_header">
  <div class="container pt-3">
    <div class="row">
      <!-- <div class="col-lg-2 d-none d-lg-block mt-1">
        <div class="row ">
          <a href="https://www.facebook.com/Decorazzia-825801597539175/" target="_blank"><img class="navbar__icons"
              src="assets/icons/facebook.png" alt="facebook" /></a>
          <a href="https://www.instagram.com/decorazzia/" target="_blank" class="social-intragram"><img
              class="navbar__icons" src="assets/icons/instagram.png" alt="instagram" />
          </a>
          <a href="https://www.tiktok.com/@decorazzia?_t=8pRy1yNq7Dq&_r=1" target="_blank" class="social-intragram"><img
              class="navbar__icons" src="assets/icons/tiktok.png" alt="tiktok" />
          </a>
          <span class="m-0 ml-2">Síguenos!</span>
        </div>
      </div> -->
      <div class="col-12 d-flex justify-content-center">
        <span>{{campaignHeader}}</span>
      </div>
      <!-- <div class="col-lg-3 d-none d-lg-block mt-1">
        <a class="navbar__link" target=”_blank”
          href="https://api.whatsapp.com/send?phone=573014694747&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20productos.">CONTÁCTANOS
        </a>
        <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#000">
          <path
            d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
        </svg>
      </div> -->
    </div>
  </div>

</div>