<div class="header w-100 d-flex align-items-center justify-content-center">
    <!-- <img src ="../../../../assets/images/WEB- <b>DECORAZZIA</b> -SECCIÓN-FLORERÍA.jpg"> -->
    <h3 class="letter-style">
      TERMINOS Y CONDICIONES
    </h3>
</div>
<div class="row w-100 d-flex align-items-center justify-content-center">
    <div class="col-8 m-5">
        <p>
            Al ingresar, revisar y comprar en este sitio web usted se compromete a leer, informarse y
cumplir los términos y condiciones de uso, además se obliga a respetar las políticas de
privacidad de conformidad con la legislación colombiana sobre el tema. Los productos y
ofertas que se encuentran en www.decorazzia.com aplican solo para Colombia.<br><br>

<b>¿QUIÉNES SOMOS?</b><br><br>

www.decorazzia.com es un sitio de e-commerce propiedad de WILMER ZAPATA SANJUAN
persona natural que se encuentra identificada con el NIT 98700966, su dirección de
notificaciones es: calle 75 # 52d 112 correo electrónico de contacto decorazzia@gmail.com

<b>PROCESO DE COMPRA:</b><br><br>

• Las productos y precios incluidos en esta página están dirigidos al público en
general como personas indeterminadas y por consiguiente será necesaria
siempre una confirmación y aceptación de nuestra parte de su orden de compra
y del pago realizado, momento en el cual se expedirá la correspondiente factura
y se entenderá perfeccionado el contrato de venta entre el consumidor y
 <b>DECORAZZIA</b>.<br>
• En www.decorazzia.com se ofrece el servicio de pago contra entrega, este solo
aplicará para la ciudad de Medellín y el área metropolitana.<br>
• Los pagos en línea se realizarán por medio de la plataforma de pago PayU Latam
con Tarjeta de Crédito (Visa, MasterCard, American Express y Diners Club);
Efectivo por (Efecty, Su red y baloto); Pago en Bancos (Davivienda, Banco de
Bogotá, Grupo Aval y Bancolombia). Una vez realizado el pago y emitida la orden
de compra, la misma se confirma sistemáticamente por correo electrónico al
comprador.<br><br>

<b>PROMESA DE ENTREGA</b><br><br>

La promesa general de entrega de un producto adquirido a través de esta página web será
de cinco (5) días hábiles, que se comenzarán a contar a partir del día siguiente a la
aceptación de la orden de compra.<br>
La venta estará sujeta a disponibilidad del inventario, si al realizar el alistamiento del
producto no hay disponibilidad del mismo  <b>DECORAZZIA</b>  se comunicará con el cliente para
resolver la situación.<br><br>

    </p>

    </div>
    
</div>