
<div class="container d-flex justify-content-center">
  <div class="col-12" *ngIf="!showLoader">
    <div class="row">
     <!--  <div class="col-lg-2 col-md-12 mt-3">
        <a class="btn btn-view d-sm-block d-md-block d-lg-none" data-toggle="collapse" href="#collapseExample"
          role="button" aria-expanded="false" aria-controls="collapseExample">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
            <path
              d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
          </svg>
        </a>
        <div class="collapse d-sm-none d-md-none d-lg-block mt-3" id="collapseExample">
          <form [formGroup]="searchGroup">
            <div class="form-group col-12">
              <label class="font-weight-bold">FILTRAR CATEGORÍAS</label>
              <select id="inputState" title="CATEGORÍAS" class="w-100" formControlName="category"
                (change)="getSubcategory()">
                <option selected></option>
                <option *ngFor="let cat of categoriesList">{{ cat.category }}</option>
              </select>
            </div>
            <div class="form-group col-12">
              <select id="inputState" title="CATEGORÍAS" class="w-100" formControlName="subcategory"
                (change)="getCategorySubcategory()">
                <option selected></option>
                <option *ngFor="let cat of subcategoriesList">{{ cat.category }}</option>
              </select>
            </div>
            <div class="input-group col-12">
              <input type="text" placeholder="COLOR, TAMAÑO, FORMA" aria-describedby="basic-addon2"
                formControlName="criteria" class="w-100" />
            </div>
            <div class="form-group col-12">
              <div class="row">
                <div class="col-12"><label class="font-weight-bold">PRECIOS</label></div>
                <div class="col-12"><button class="button-style-card w-100">HASTA $65.000</button></div>
                <div class="col-12"><button class="button-style-card w-100">$65.000 A $150.000</button></div>
                <div class="col-12"><button class="button-style-card w-100">MÁS DE $150.000</button></div>
                <div class="col-12"><button class="btn btn-view mt-1" role="button" (click)="clearFilter()">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                      fill="#e8eaed">
                      <path
                        d="m592-481-57-57 143-182H353l-80-80h487q25 0 36 22t-4 42L592-481ZM791-56 560-287v87q0 17-11.5 28.5T520-160h-80q-17 0-28.5-11.5T400-200v-247L56-791l56-57 736 736-57 56ZM535-538Z" />
                    </svg>
                  </button></div>
                <div class="col-12"><button class="btn button-style w-100 mt-1" role="button"
                    (click)="clearFilter()">
                    FILTRAR
                  </button></div>
  
              </div>
            </div>
          </form>
        </div>
      </div> -->
  
      <!-- <div class="col-lg-10 col-md-12"> -->
      <div class="col-12">
        <div class="row">
          <div class="col-md-4 col-lg-4  mt-4" *ngFor="let product of producstsList; let i = index">
            <div class="card">
              <div class="card-body">
                <div style="position: relative; cursor: pointer" (click)="onView(product)">
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal?.offer }}</small>
                  </div>
                  <img class="w-100" src="{{ product?.image[0] }}" alt="Card image cap" />
                </div>
                <div class="row mt-3 description-div">
                  <div class="col-12 d-flex justify-content-center">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small>
                  </div>
                </div>
                <div class="row m-1 detail-div">
                  <div class="col-12 d-flex justify-content-center"> 
                    <small style="font-size: 1.5rv">
                    {{ product.details | case  }}</small>
                  </div>
                </div>              
                <div class="row m-0 p-0">
                  <div class="col-12 mt-3 button-style-card" (click)="addToCar(product)" style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    ">
                    <img style="width: 30px; margin-right: 15px" src="assets\icons\y.png" alt="compras" />
                    {{ product.price | currency : '$ ' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="show-more-div w-80" *ngIf="currUrl !== '/productos/likes' && this.viewMore">
          <button class="button-style" (click)="showMore()">VER MÁS</button>
        </div>
      </div>
    </div>
  </div>
  <app-spinner *ngIf="showLoader"></app-spinner>
</div>