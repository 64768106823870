import { Component, OnInit, Pipe } from "@angular/core";
import { Product } from "../../models/products";
import { CardsService } from "../../services/cards.service";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { CatalogService } from "../../services/catalog.service";
import { VariablesService } from "../../services/variables.service";
import { ToastrService } from "ngx-toastr";
import { CognitoService } from "../../services/cognito.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CategoryService } from "../../services/category.service";

@Component({
  selector: "app-cards",
  templateUrl: "./cards.component.html",
  styleUrls: ["./cards.component.scss"],
})
export class CardsComponent implements OnInit {
  public urlS3 = environment.urlBucketS3;
  index = 8;
  public totalCar;
  public increment: number = 1;
  public carList: any = [];
  categoriesList: any[] = [];
  subcategoriesList: any[] = [];
  currUrl: string = "";
  likes: any[] = [];
  showLoader: boolean = false;
  searchGroup: FormGroup;
  viewMore: boolean = true;
  constructor(
    private cardsService: CardsService,
    private router: Router,
    private products: CatalogService,
    private variableService: VariablesService,
    private toastr: ToastrService,
    private categories: CategoryService,
    private auth: CognitoService,
    private formBuilder: FormBuilder,
  ) {
    this.categories.getCategories().subscribe((res) => {
      this.categoriesList = res["body"].filter((res) => !res.hiden);
    });

    this.getProducts(0, 9);
    this.variableService.totalCar.subscribe((res) => {
      this.totalCar = res;
    });

    this.searchGroup = formBuilder.group({
      category: [""],
      subcategory: [""],
      price: [""],
      color: [""],
      criteria: [""]
    });
  }

  getProduct: Product = new Product();

  producstsList: any[] = [];
  productsStorage;

  ngOnInit(): void {
    this.currUrl = window.location.pathname;
    this.carList = JSON.parse(localStorage.getItem("carList"));
  }

  onView(product: Product) {
    this.router.navigate([`/detalles/${product.sk}`]);
    this.products.postView(product).subscribe();
  }

  search() {
    if (this.searchGroup.valid)
      this.router.navigate([
        "/productos",
        this.searchGroup.controls["criteria"].value,
        "sale",
      ]);
  }

  getSubcategory() {
    this.getProductsAll();
    this.viewMore = false;
    this.subcategoriesList = this.categoriesList.filter(cat => cat.category === this.searchGroup.value.category)[0]['sub-categories']
    this.producstsList = this.producstsList.filter(prod => prod.category.toUpperCase() === this.searchGroup.value.category)
  }


  clearFilter() {
    this.searchGroup.reset();
    this.viewMore = true;
    this.getProductsAll();
  }

  getCategorySubcategory() {
    this.producstsList = this.producstsList.filter(prod => prod.subcategory.toUpperCase() === this.searchGroup.value.subcategory)

  }

  getProductsAll() {
    this.showLoader = true;
    this.cardsService.cards.subscribe((products) => {
      if (this.auth.getId()) {
        this.products.getLikes(this.auth.getId()["email"]).subscribe((res) => {
          this.likes = res["body"]["body"]["products"];
          if (products.length <= 8) {
            this.producstsList = products.map((item) => {
              if (this.likes.includes(item.sk)) {
                return { ...item, liked: true };
              } else return item;
            });
          } else {
            this.producstsList = products
              .map((item) => {
                if (this.likes.includes(item.sk)) {
                  return { ...item, liked: true };
                } else return item;
              });
          }
          setTimeout(() => {
            this.showLoader = false;
          }, 1100);
        });
      } else {
        if (products.length <= 8) {
          this.producstsList = products;
        } else {
          this.producstsList = products;
        }
        this.showLoader = false;

      }
    });
  }



  getProducts(page, index) {
    this.showLoader = true;
    this.cardsService.cards.subscribe((products) => {
      if (!this.auth.isTokenExpired()) { //OJO
        this.products.getLikes(this.auth.getId()["email"]).subscribe((res) => {
          if (res["body"]["body"]) {
            this.likes = res["body"]["body"]["products"];
            if (products.length <= 9) {
              this.producstsList = products.map((item) => {
                if (this.likes.includes(item.sk)) {
                  return { ...item, liked: true };
                } else return item;
              });
            } else {
              this.producstsList = products
                .slice(0, page * 2 + index)
                .map((item) => {
                  if (this.likes.includes(item.sk)) {
                    return { ...item, liked: true };
                  } else return item;
                });
            }
            setTimeout(() => {
              this.showLoader = false;
            }, 1100);
          } else {
            if (products.length <= 8) {
              this.producstsList = products;
            } else {
              this.producstsList = products.slice(0, page * 2 + index);
            }
            this.showLoader = false;
          }
        });

      } else {
        if (products.length <= 8) {
          this.producstsList = products;
        } else {
          this.producstsList = products.slice(0, page * 2 + index);
        }
        this.showLoader = false;

      }
    });
  }

  likeProduct(product: Product) {
    if (this.auth.getId()) {
      this.likes.push(product.sk);
      this.products
        .postLike(this.auth.getId()["email"], this.likes)
        .subscribe((res) => {
          this.producstsList = this.producstsList.map((item) => {
            if (item.sk === product.sk) return { ...item, liked: true };
            else return item;
          });
        });
    } else this.router.navigate(['/login'])
  }

  unlikeProduct(product: Product) {
    this.likes = this.likes.filter((item) => item !== product.sk);
    this.products
      .postLike(this.auth.getId()["email"], this.likes)
      .subscribe((res) => {
        this.producstsList = this.producstsList.map((item) => {
          if (item.sk === product.sk) return { ...item, liked: false };
          else return item;
        });
      });
  }

  public addToCar(product: Product) {
    product.count = +this.updateCar(product);
    localStorage.getItem("carrito");
  }

  showMore() {
    this.index += 8;
    this.getProducts(1, this.index);
  }

  public updateCar(product: Product) {
    this.carList = JSON.parse(localStorage.getItem("carList"));
    let arrayAux: Product[] = [];
    let found: boolean = false;
    if (this.carList && this.carList.length > 0) {
      this.carList.forEach((item) => {
        if (item.sk === product.sk) {
          arrayAux.push(product);
          this.toastr.success("Producto actualizado.", "", {
            timeOut: 1500,
          });
          found = true;
        } else {
          arrayAux.push(item);
        }
      });
      if (!found) {
        arrayAux.push(product);
        this.variableService.getTotalItemsCar(this.totalCar + this.increment);
        this.toastr.success("Se agregó el producto a la bolsa.", "", {
          timeOut: 1500,
        });
      }
    } else {
      this.variableService.getTotalItemsCar(this.totalCar + this.increment);
      this.toastr.success("Se agregó el producto a la bolsa.", "", {
        timeOut: 1500,
      });
      arrayAux.push(product);
    }
    localStorage.setItem("carList", JSON.stringify(arrayAux));
    localStorage.setItem("carrito", JSON.stringify(arrayAux.length));
  }
}
