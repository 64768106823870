import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ComprasComponent } from "./screens/compras/compras.component";
import { ContactComponent } from "./screens/contact/contact.component";
import { HomeComponent } from "./screens/home/home.component";
import { CardsComponent } from "./shared/components/cards/cards.component";
import { PagenotfoundComponent } from "./screens/pagenotfound/pagenotfound.component";
import { SesionFloreriaComponent } from "./shared/components/sesion-floreria/sesion-floreria.component";
import { SesionNavidadComponent } from "./shared/components/sesion-navidad/sesion-navidad.component";
import { SesionRelojeriaComponent } from "./shared/components/sesion-relojeria/sesion-relojeria.component";
import { CardDetailComponent } from "./shared/components/card-detail/card-detail.component";
import { MisComprasComponent } from "./screens/mis-compras/mis-compras.component";
import { PaymentInformationComponent } from "./screens/payment-information/payment-information.component";
import { PaymentcompletedpageComponent } from "./screens/paymentcompletedpage/paymentcompletedpage.component";
import { TermsConditionsComponent } from "./screens/terms-conditions/terms-conditions.component";
import { ReturnsExchangesComponent } from "./screens/returns-exchanges/returns-exchanges.component";
import { InformationTreartmentComponent } from "./screens/information-treartment/information-treartment.component";
import { SubcategoryComponent } from "./shared/components/subcategory/subcategory.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { CallbackComponent } from "./shared/components/callback/callback.component";
import { LoginComponent } from "./shared/components/login/login.component";
import { ConfirmSignupComponent } from "./shared/components/confirm-signup/confirm-signup.component";
import { ForgetPasswordComponent } from "./shared/components/forget-password/forget-password.component";
import { NewPasswordComponent } from "./shared/components/new-password/new-password.component";
import { ModuleComponent } from "./shared/components/module/module.component";
import { ServicesComponent } from "./shared/components/services/services.component";
import { ProductsComponent } from "./shared/components/products/products.component";
import { LikedProductsComponent } from "./shared/components/liked-products/liked-products.component";
import { UserProfileComponent } from "./shared/components/user-profile/user-profile.component";
import { ServiceDetailComponent } from "./shared/components/service-detail/service-detail.component";
import { NewProductsComponent } from "./shared/components/new-products/new-products.component";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: HomeComponent },
  { path: "contact", component: ContactComponent },
  { path: "compras", component: MisComprasComponent },
  { path: "floreria", component: SesionFloreriaComponent },
  { path: "navidad", component: SesionNavidadComponent },
  { path: "decoracion", component: SesionRelojeriaComponent },

  { path: "module/:category", component: ModuleComponent },
  { path: "module/:category/:subcategory", component: ModuleComponent },

  { path: "servicios", component: ServicesComponent },
  { path: "servicios/:service", component: ServiceDetailComponent },

  { path: "productos/:criteria/sale", component: ProductsComponent },
  {
    path: "productos/likes",
    component: LikedProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "productos/nuevos",
    component: NewProductsComponent,
  },

  { path: "detalles/:sk", component: CardDetailComponent },
  { path: "subcategorias", component: SubcategoryComponent },
  { path: "terminos-condiciones", component: TermsConditionsComponent },
  { path: "devoluciones-cambios", component: ReturnsExchangesComponent },
  { path: "tratamiento-datos", component: InformationTreartmentComponent },
  { path: "confirmacion-pago", component: PaymentcompletedpageComponent },

  {
    path: "informacion-pago",
    component: PaymentInformationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "user/profile",
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  { path: "favoritos", component: PaymentInformationComponent },

  { path: "login", component: LoginComponent },
  { path: "confirmar-cuenta", component: ConfirmSignupComponent },
  { path: "resetear-password", component: ForgetPasswordComponent },
  { path: "nueva-password", component: NewPasswordComponent },
  { path: "login/callback", component: CallbackComponent },

  { path: "404", component: PagenotfoundComponent },
  { path: "**", redirectTo: "404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
