<div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" *ngIf="slides[0]">
      <img class="d-block w-100" [src]="slides[0]?.src" alt="First slide" >
    </div>
    <div class="carousel-item" *ngIf="slides[1]">
      <img class="d-block w-100" [src]="slides[1]?.src" alt="Second slide" >
    </div>
    <div class="carousel-item" *ngIf="slides[2]">
      <img class="d-block w-100" [src]="slides[2]?.src" alt="Third slide" >
    </div>
    <div class="carousel-item"  *ngIf="slides[3]">
      <img class="d-block w-100" [src]="slides[3]?.src" alt="Third slide">
    </div>
  </div>
</div>