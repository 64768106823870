import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Product } from '../models/products';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' ,
     'x-api-key': environment.apikey 
    }),
    observe: 'response' as 'response',
  };

  constructor(private http: HttpClient) {   }

  private url = environment.url
  private urlDD = environment.urlDD


  public getCatalog(){
    return this.http.get(`${this.url}productos`, this.httpOptions)
  }

  public postLike(id,products){
    return this.http.post(`${this.url}productos/like`,{            
      id: id,
      products: products
    } ,this.httpOptions)
  }

  public getLikes(id){
    return this.http.get(`${this.url}productos/like?id=${id}`,  this.httpOptions)
  }

  public postView(product:Product){
    return this.http.post(`${this.url}productos/view`, {      
      sk: product.sk,
      hk: product.hk,
      action: "view"
    } ,this.httpOptions)
  }

  public getDepartments(){
    return this.http.get(`${this.urlDD}/departments?type=all&department=all`)
  }

  public getMunicipality(department){
    return this.http.get(`${this.urlDD}/departments?type=department&department=${department}`)
  }

  public getSubcategories(){
    return this.http.get(`${this.url}descripciones`,this.httpOptions)
  }
}
