import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubscriptionService } from '../../services/subscription.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  subscriptionGroup: FormGroup;
  validStatus: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private suscription: SubscriptionService,
    private toastr: ToastrService,


  ) {
    this.subscriptionGroup = formBuilder.group({
      email: ["", Validators.required],
      names: ["", Validators.required],
    });
  }

  ngOnInit(): void {
  }

  postSub() {
    this.suscription.putSubscription({...this.subscriptionGroup.getRawValue(), enable:true}).subscribe(res=>{
      this.subscriptionGroup.reset()
      this.toastr.success("SUSCRIPCIÓN EXITOSA.", "", {
        timeOut: 1500,
      });
    })
  }

  changeValidStatus() {
    this.validStatus = !this.validStatus;
  }


}
