import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthServiceService } from "../../services/auth-service.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-callback",
  templateUrl: "./callback.component.html",
  styleUrls: ["./callback.component.scss"],
})
export class CallbackComponent implements OnInit {
  loginUrl: string = `${environment.login}login?client_id=${
    environment.cliendId
  }&response_type=code&scope=openid+profile&redirect_uri=${encodeURI(
    environment.redirectLogin
  )}`;

  constructor(private route: ActivatedRoute, private auth: AuthServiceService) {
    const code = this.route.snapshot.queryParamMap.get("code");
    if (code) this.auth.getToken(code);
    else {
      if (this.auth.isTokenExpired()) {
        window.location.href = this.loginUrl;
      }
    }
  }

  ngOnInit(): void {}
}
