import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contact } from 'src/app/shared/models/contact';
import { ContactService } from 'src/app/shared/services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public formContact: FormGroup;
  public showMessage:boolean= false;
  public showError:boolean= false;
  public message:String ='';
  public innerWidth: any =  window.innerWidth;
  hide:boolean = true;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth>768)
      this.hide = true;
    else  
      this.hide=false;
  }


  constructor(
    private contact: ContactService,
    private formBuilder: FormBuilder

  ) { }

  

  ngOnInit() {

    this.formContact = this.formBuilder.group({
      name : ['', Validators.required],
      email :['', [Validators.required, Validators.email]],
      cellphone :[, Validators.required],
      message :['', Validators.required]
    })
  }

  public onSubmit(){
    this.showMessage=false;
    this.showError=false;
    this.contact.sendEmail(this.formContact.value).subscribe(response=>{
      if (response.body['success']) {
        this.showMessage=true;
        this.message=response.body['message'];
      }else{
        this.showError=true;
        this.message=response.body['message'];
      }
      this.formContact.reset()
    });
    
}

}
