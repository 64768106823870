import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CardsService } from '../services/cards.service';
import { CatalogService } from '../services/catalog.service';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent implements OnInit {
  public subCategories:string[]=[]

  constructor(private card:CardsService,
              private route:Router,
              private catalog:CatalogService
              ) { }

  ngOnInit(): void {

    
    this.catalog.getSubcategories().subscribe(response=>{
      if(response.body['body']['subCategory']){

        if ( window.location.pathname === "/navidad")
          this.subCategories = response.body['body']['xmasCategory']
        else
          this.subCategories = response.body['body']['subCategory']


      }
    })
  }

  routeTo(subcategory:String){
     if(subcategory!==''){
      this.route.navigate(['/subcategorias'])
      this.card.updateSubcategory(subcategory);
     }
  }

}
