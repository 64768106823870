import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CardsService } from "../../services/cards.service";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit, OnDestroy {
  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private cardsService: CardsService
  ) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  //productos/:criteria/sale
  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      const criteria = params["criteria"];
      this.cardsService.getProducts().subscribe((resp) => {
        let products = [];
        products = [
          ...resp.body["body"].filter(item=> item["category"].toUpperCase().includes(criteria.toUpperCase())),
          ...resp.body["body"].filter(item=> item.subcategory.toUpperCase().includes(criteria.toUpperCase())),
          ...resp.body["body"].filter(item=> item.description.toUpperCase().includes(criteria.toUpperCase())),
          ...resp.body["body"].filter(item=> item.details.toUpperCase().includes(criteria.toUpperCase()))
        ];

        resp.body["success"]
          ? this.cardsService.updateCardProducts(products)
          : [];
      });
    });
  }
}
