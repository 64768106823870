<div class="row m-0 p-0 mb-3 text-uppercase" >
    <div class="col-md-3 m-0 p-0 d-none d-md-flex">
        <img class="left-img" src="assets\icons\f.png">
    </div>
    <div class="col-sm-12 col-md-6 mt-5">
        <div class="row mt-5">
            <div class="col-12 d-flex justify-content-center m-0 p-0">
                <p class="head-tittle text-center">
                    SUSCRÍBETE Y RECIBE 10% DE DESCUENTO <br> EN TU PRIMERA COMPRA
                </p>
            </div>

        </div>
        <div class="row mt-2">
            <div class="col-12 d-flex justify-content-center m-0 p-0">
                <p class="text-center">
                    Compártenos tu e-mail y te enviaremos los detalles, productos y <br>ofertas más inspiradoras, llenas
                    de magia y repletos de amor.
                </p>
            </div>
        </div>
        <form [formGroup]="subscriptionGroup">
            <div class="row d-flex w-100 mt-4 ">
                <div class="col-md-6 col-sm-12">
                    <label for="email">ESCRIBE TU E-MAIL</label><br>
                    <input class="w-100" type="text" id="email" formControlName="email" />
                </div>
                <div class="col-md-6 col-sm-12">
                    <label for="names">NOMBRE Y APELLIDO</label><br>
                    <input class="w-100" type="text" id="names" formControlName="names" />
                </div>
            </div>
            <div class="d-flex w-100 mt-3 d-flex justify-content-center">
                <input type="checkbox" class="checkbox" value="" id="flexCheckDefault"  (change)="changeValidStatus()" />
                <label class="checkbox-label ml-3" for="flexCheckDefault">
                    Acepto recibir correos electrónicos con noticias y promociones de Decorazzia. Acepto y autorizo el
                    tratamiento de mis datos personales y de mi información financiera. Consulta aquí nuestra <a
                        href="/tratamiento-datos" target="_blank">
                        política de tratamiento de privacidad.</a>
                </label>
            </div>
            <div class="d-flex justify-content-center w-100 mt-4">
                <button class="button-style" [disabled]="!this.validStatus " (click)="postSub()">ENVIAR</button>
            </div>
        </form>
    </div>
    <div class="col-md-3 justify-content-end m-0 p-0 d-none d-md-flex">
        <img class="right-img" src="assets\icons\f.png">
    </div>

</div>