import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentResponse } from 'src/app/shared/models/paymentResponse';
import { ShopService } from 'src/app/shared/services/shop.service';
import { VariablesService } from 'src/app/shared/services/variables.service';

@Component({
  selector: 'app-paymentcompletedpage',
  templateUrl: './paymentcompletedpage.component.html',
  styleUrls: ['./paymentcompletedpage.component.scss']
})
export class PaymentcompletedpageComponent implements OnInit {
  public confirmed:boolean=false;
  public message:string=''
  public transaction: PaymentResponse = new PaymentResponse();
  constructor(private routerInterceptor:ActivatedRoute,
    private router: Router,
    private shop: ShopService,
    private variableService:VariablesService
    ) { 

    this.routerInterceptor.queryParams
      .subscribe(params=> {
        this.transaction.lapTransactionState = params.lapTransactionState
        this.transaction.referenceCode = params.referenceCode
        this.transaction.transactionId = params.transactionId
        this.transaction.description = params.description
        this.transaction.amount = localStorage.getItem('amount')
        this.transaction.customer = localStorage.getItem('customer')
        this.transaction.document = parseInt(localStorage.getItem('document'))
        this.confirmed=true;
        switch (params.transactionState) {
          case '4':
            this.message = 'APROBADA';
            break;
          case '6':
            this.message = 'RECHAZADA';
            break;
          case '104':
            this.message = 'ERROR EN LA TRANSACCION';
            break;
          case '7':
            this.message = 'PENDIENTE POR CONFIRMAR';
            break;
          default:
            this.message = 'TRANSACCIÓN CON ERRORES';
            break;
        }
        // this.confirmTransaction(this.transaction);
      });

  }

  ngOnInit(): void {
  }

  public redirect(url){
    this.router.navigate([url]);
  }

  public confirmTransaction(transaction:PaymentResponse){
    let paymentInformation={
      "paymentReference": transaction.referenceCode,
      "document": transaction.document
    }
    this.shop.confirmTransactionStatus(paymentInformation).subscribe(response=>{
      if(response['body']['success']){
        let msj = response['body']['message'];
        this.message = msj.toUpperCase();
        this.confirmed=true;
        localStorage.clear()
        this.variableService.updateCarCountVariable(0);
      }else{
        this.message = response['body']['message']
        this.confirmed=true;
      }
    });
  }

}

//?merchantId=508029&merchant_name=Test+PayU+Test+comercio&merchant_address=Av+123+Calle+12&telephone=7512354&merchant_url=http%3A%2F%2Fpruebaslapv.xtrweb.com&transactionState=6&lapTransactionState=DECLINED&message=DECLINED&referenceCode=5f931b0e-b7a4-4aed-bb51-781e056c160d&reference_pol=120899074&transactionId=09c688f4-b989-45e9-b8f6-2399a7b5887d&description=Test+PAYU&trazabilityCode=09c688f4-b989-45e9-b8f6-2399a7b5887d&cus=09c688f4-b989-45e9-b8f6-2399a7b5887d&orderLanguage=es&extra1=&extra2=&extra3=&polTransactionState=6&signature=e4f63c0a39fef70203d2fc6abf277361&polResponseCode=9999&lapResponseCode=INTERNAL_PAYMENT_PROVIDER_ERROR&risk=&polPaymentMethod=11&lapPaymentMethod=MASTERCARD&polPaymentMethodType=2&lapPaymentMethodType=CREDIT_CARD&installmentsNumber=1&TX_VALUE=16200.00&TX_TAX=.00&currency=COP&lng=es&pseCycle=&buyerEmail=williams.pachon%40gmail.com&pseBank=&pseReference1=&pseReference2=&pseReference3=&authorizationCode=&TX_ADMINISTRATIVE_FEE=.00&TX_TAX_ADMINISTRATIVE_FEE=.00&TX_TAX_ADMINISTRATIVE_FEE_RETURN_BASE=.00&processingDate=2020-11-13