<div class="container mt-5 mb-5">
  <div class="row">
    <div class="col-12">
      <h4 class="contacto">CATEGORÍAS POPULARES</h4>
    </div>
  </div>
</div>
<div class="container mt-5 mb-5">
  <div class="card-group">
    <div
      class="card transition"
      *ngFor="let cat of topCategories"
      (click)="redirectSubcategories(cat.category, cat.name)"
    >
      <img src="{{ cat.image }}" class="card-img-top" alt="..." />
      <div class="card-body">
        <p class="card-text paragraph">
          {{ cat.name }}
        </p>
      </div>
    </div>
  </div>
</div>
