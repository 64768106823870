import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' ,
     'x-api-key': environment.apikey 
    }),
    observe: 'response' as 'response',
  };
  private url = environment.url
  private productSource = new BehaviorSubject<any>({});
  public  product = this.productSource.asObservable();

  constructor(private http: HttpClient) { }

  public updateProductVariable(productService){  
    this.productSource.next(productService);    
  }

  public checkProductAvailability(paymentInformation){  
    return this.http.put(`${this.url}productos/reserve`, paymentInformation, this.httpOptions).pipe(
      map((res:any)=>{
        if(res.body.errorType){
          throw new Error();
        }        
        return res;
      }),
      retry(3)
    )    
  }

  public createPayment(paymentInformation){
    return this.http.post( `${this.url}pagos`, paymentInformation, this.httpOptions);
  }

  public confirmPaymentStatus(paymentInformation){
    return this.http.post( `${this.url}pagos/confirmacion`, paymentInformation, this.httpOptions);
  }

  public confirmTransactionStatus(paymentInformation){
    return this.http.post(this.url+'pagos/confirmacion', paymentInformation, this.httpOptions);
  }
 
}
