import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CognitoService } from "../../services/cognito.service";

@Component({
  selector: "app-confirm-signup",
  templateUrl: "./confirm-signup.component.html",
  styleUrls: ["./confirm-signup.component.scss"],
})
export class ConfirmSignupComponent implements OnInit {
  inputs = ["input1", "input2", "input3", "input4", "input5", "input6"];
  constructor(private router: Router, private cognito: CognitoService) {}
  dissable: boolean = true;
  showLink: boolean = false;
  ngOnInit(): void {
    /* if (this.cognito.username === "") {
      this.router.navigate(["/home"]);
    } */
    this.timeOut();
    //this.cognito.resendConfirmationEmail();
  }

  timeOut() {
    setTimeout(() => {
      this.showLink = true;
      this.dissable = false;
    }, 5000);
  }

  clear() {
    this.inputs.forEach((id) => {
      document.getElementById(id)["value"] = "";
    });
  }
  confirm() {
    let values = this.inputs.map((id) => {
      const input = document.getElementById(id);
      return input["value"];
    });
    this.cognito.confirmAccount(values.join(""));
    this.clear();
  }

  resend() {
    this.cognito.resendConfirmationEmail();
  }

  login() {
    this.cognito.loginConfirm();
  }

  onSearchChange(e) {
    const target = e.target;
    const val = target.value;

    if (isNaN(val)) {
      target.value = "";
      return;
    }

    if (val != "") {
      const next = target.nextElementSibling;
      if (next) {
        next.focus();
      }
    }
  }

  onKeyUp(e) {
    const target = e.target;
    const key = e.key.toLowerCase();

    if (key == "backspace" || key == "delete") {
      target.value = "";
      const prev = target.previousElementSibling;
      if (prev) {
        prev.focus();
      }
      return;
    }
  }
}
