import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CognitoService } from "../../services/cognito.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  signInForm: FormGroup;
  signUpForm: FormGroup;
  showAlert: boolean = false;
  message: string = "";
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private cognito: CognitoService
  ) {}

  ngOnInit(): void {
    this.signInForm = this.formBuilder.group({
      password: ["", [Validators.required, Validators.minLength(8)]],
      username: ["", Validators.required],
    });
    this.signUpForm = this.formBuilder.group({
      password: ["", [Validators.required, Validators.minLength(8)]],
      username: ["", [Validators.required, Validators.email]],
      birthdate: ["", Validators.required],
      family_name:["", Validators.required],
      gender:["", Validators.required],
      given_name:["", Validators.required],
      phone_number:["", [Validators.required,Validators.pattern('[- +()0-9]+')]]
    });
  }

  signIn() {
    this.cognito.login(this.signInForm.value.username, this.signInForm.value.password)
  }

  signUp(){    
    this.cognito.signUp(this.signUpForm.getRawValue())
  }
}
