<div class="container-fluid container__compras container_page">
  <div class="row card-row mt-5">
    <div class="col-sm-12 col-md-7 col-lg-7 m-0 p-0 card-images">
      <div class="col-sm-8 col-md-12 col-lg-12" style="position: relative">
        <div id="carouselExampleControls" class="carousel slide" style="position: relative" data-ride="carousel">
          <div class="carousel-inner">
            <div *ngIf="
                product?.deal?.active" class="offer">
              <small>-{{ product?.deal?.offer }}</small>
            </div>
            <div class="carousel-item active">
              <img src="{{ this.selectedImage }}" class="d-block imag-card" />
            </div>
            <div class="carousel-item" *ngFor="let image of this.imageList | slice : 1">
              <img src="{{ image }}" class="d-block imag-card" />
            </div>
          </div>
          <a class="carousel-control-prev" *ngIf="this.imageList.length > 1" href="#carouselExampleControls"
            role="button" data-slide="prev">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style="color: black" fill="currentColor"
              class="bi bi-caret-left-square-fill" viewBox="0 0 16 16">
              <path
                d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm10.5 10V4a.5.5 0 0 0-.832-.374l-4.5 4a.5.5 0 0 0 0 .748l4.5 4A.5.5 0 0 0 10.5 12z" />
            </svg>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next"
            *ngIf="this.imageList.length > 1">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" style="color: black" height="20" fill="currentColor"
              class="bi bi-caret-right-square-fill" viewBox="0 0 16 16">
              <path
                d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.5 10a.5.5 0 0 0 .832.374l4.5-4a.5.5 0 0 0 0-.748l-4.5-4A.5.5 0 0 0 5.5 4v8z" />
            </svg>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-start col-sm-12 col-md-5 col-lg-5 card-data p-2">
      <div class="row w-80 d-flex justify-content-start mb-3">
        <span>{{ product?.description }}</span>
      </div>
      <div class="row card-price mt-2 w-100 d-flex justify-content-start">
        <div class="col-sm-12 col-md-5 col-lg-5">
          <div class="row" *ngIf="product?.deal?.active">
            <small style="color: #8f8f8f; text-decoration: line-through">{{
              product.fakePrice | currency : " $ "
              }}</small>
          </div>
          <div class="row">
            <p class="card-price-letter">
              {{ product?.price | currency : " $ " }}
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-5 col-lg-5 d-flex justify-content-end align-items-end">
          <p class="card-stok-letter d-flex justify-content-start align-items-end">
            IN STOCK
          </p>
        </div>
      </div>
      <div class="dropdown-divider w-100"></div>

      <div class="row w-100 card-description d-flex justify-content-start mt-4">
        <p>
          {{ product?.details }}
        </p>
      </div>
      <div class="row w-100 mt-5 d-flex justify-content-start">
        <div class="col-1 d-flex justify-content-start m-0 p-0 mr-3" [formGroup]="productForm">
          <input formControlName="count" class="form-control " min="1" type="number" />
        </div> 
        <div class="col-10 d-flex justify-content-start">
          <button id="algo" class="btn-view"  (click)="addToCar(product)">
            AÑADIR AL CARRITO
          </button>
        </div>
      </div>
      <div class="row w-100 mt-3 d-flex justify-content-start">
        <div class="d-flex justify-content-start m-0 p-0 ml-1">
          <div class=" d-flex justify-content-start my-float">
            <a href="{{buyMessage}}" target=”_blank”>
              <i class="fa fa-whatsapp ml-2 mt-2"> COMPRAR POR WHATSAPP!</i>
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- <app-popular-products></app-popular-products>
 -->