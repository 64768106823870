import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  innerWidth: any;
  showLarge = false;
  showMid = false;
  showSm = false;
  comments = [{
    name: "Williams Pachón",
    comment: "Un lugar que no solo se especializa en artículos de navidad como nos tenía acostumbrados sino que durante el resto del año ofrece una gran variedad de artículos para la decoración del hogar."
  }, {
    name: "Williams Pachón",
    comment: "Un lugar que no solo se especializa en artículos de navidad como nos tenía acostumbrados sino que durante el resto del año ofrece una gran variedad de artículos para la decoración del hogar."
  },
  {
    name: "Williams Pachón",
    comment: "Un lugar que no solo se especializa en artículos de navidad como nos tenía acostumbrados sino que durante el resto del año ofrece una gran variedad de artículos para la decoración del hogar."
  }];

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth < 1028 && this.innerWidth > 765) {
      this.showMid = true;
      this.showSm = this.showLarge = false;
    } else if (this.innerWidth >= 1028) {
      this.showLarge = true;
      this.showSm = this.showMid = false;
    } else if( this.innerWidth <= 765){
      this.showSm = true;
      this.showLarge = this.showMid = false;
    } 
  }

  constructor() { }

  ngOnInit(): void {
    this.onResize();
  }

}
