import { Component, HostListener, OnInit } from '@angular/core';
import { CardsService } from '../../services/cards.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sesion-relojeria',
  templateUrl: './sesion-relojeria.component.html',
  styleUrls: ['./sesion-relojeria.component.scss']
})
export class SesionRelojeriaComponent implements OnInit {
  public s3banner = environment.urlAssetsS3 
  public innerWidth: any =  window.innerWidth;
  hide:boolean = true;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth>768)
      this.hide = true;
    else  
      this.hide=false;
  }

  constructor(private cardsService:CardsService) {
    this.cardsService.getProducts()
    .subscribe(resp => {      
      resp.body['success']
      ?cardsService.updateCardProducts(resp.body['body'].filter(res=>res.category=='Decoración'))
      :[];
    });
  }
  ngOnInit(): void {
    this.onResize()
  }

}
