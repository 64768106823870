import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cards-category',
  templateUrl: './cards-category.component.html',
  styleUrls: ['./cards-category.component.scss']
})
export class CardsCategoryComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

    
  public redirect(redirect){
    this.router.navigate([redirect]);
  }
}
