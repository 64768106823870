<div class="container  mt-5 mb-5">
  <div class="row">
    <div class="col-12">
      <h4 class="contacto">OFERTAS ESPECIALES</h4>
    </div>
  </div>
</div>
<div class="container h-75" *ngIf="showLarge">
  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-ride="carousel"
  >
    <div class="carousel-inner mb-4">
      <div class="carousel-item active">
        <div class="row">
          <div
            class="col-md-4 col-lg-3"
            *ngFor="let product of listProducts[0]?.slice(0, 4); let i = index"
          >
            <div class="card">
              <div class="card-body">
                <div
                  style="position: relative; cursor: pointer"
                  (click)="onView(product)"
                >
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal.offer }}</small>
                  </div>
                  <img
                    class="w-100"
                    src="{{ product?.image[0] }}"
                    alt="Card image cap"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small
                    >
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-5 d-block">
                    <div class="row" *ngIf="product.deal?.active">
                      <small
                        style="color: #8f8f8f; text-decoration: line-through"
                        >{{ product.fakePrice | currency : "" }}</small
                      >
                    </div>
                    <div class="row">
                      <small style="font-size: 16px; color: #8f8f8f">{{
                        product.price | currency : ""
                      }}</small>
                    </div>
                  </div>
                  <div
                    class="col-5"
                    (click)="unlikeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="M440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q84 0 153 59t69 160q0 14-2 29.5t-6 31.5h-85q5-18 8-34t3-30q0-75-50-105.5T620-760q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Zm160-280v-80h320v80H600Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-5"
                    (click)="likeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="!product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-12 mt-3"
                    (click)="onView(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    VER PRODUCTO
                  </div>
                  <div
                    class="col-12 mt-3 button-style-card"
                    (click)="addToCar(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    <img
                      style="width: 20px; margin-right: 15px"
                      src="assets/icons/compras.png"
                      alt="compras"
                    />
                    AÑADIR AL CARRITO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div
            class="col-md-4 col-lg-3"
            *ngFor="let product of listProducts[1]?.slice(0, 4); let i = index"
          >
            <div class="card">
              <div class="card-body">
                <div
                  style="position: relative; cursor: pointer"
                  (click)="onView(product)"
                >
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal.offer }}</small>
                  </div>
                  <img
                    class="w-100"
                    src="{{ product?.image[0] }}"
                    alt="Card image cap"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small
                    >
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-5 d-block">
                    <div class="row" *ngIf="product.deal?.active">
                      <small
                        style="color: #8f8f8f; text-decoration: line-through"
                        >{{ product.fakePrice | currency : "" }}</small
                      >
                    </div>
                    <div class="row">
                      <small style="font-size: 16px; color: #8f8f8f">{{
                        product.price | currency : ""
                      }}</small>
                    </div>
                  </div>
                  <div
                    class="col-5"
                    (click)="unlikeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="M440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q84 0 153 59t69 160q0 14-2 29.5t-6 31.5h-85q5-18 8-34t3-30q0-75-50-105.5T620-760q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Zm160-280v-80h320v80H600Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-5"
                    (click)="likeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="!product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-12 mt-3"
                    (click)="onView(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    VER PRODUCTO
                  </div>
                  <div
                    class="col-12 mt-3 button-style-card"
                    (click)="addToCar(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    <img
                      style="width: 20px; margin-right: 15px"
                      src="assets/icons/compras.png"
                      alt="compras"
                    />
                    AÑADIR AL CARRITO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div
            class="col-md-4 col-lg-3"
            *ngFor="let product of listProducts[2]?.slice(0, 4); let i = index"
          >
            <div class="card">
              <div class="card-body">
                <div
                  style="position: relative; cursor: pointer"
                  (click)="onView(product)"
                >
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal.offer }}</small>
                  </div>
                  <img
                    class="w-100"
                    src="{{ product?.image[0] }}"
                    alt="Card image cap"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small
                    >
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-5 d-block">
                    <div class="row" *ngIf="product.deal?.active">
                      <small
                        style="color: #8f8f8f; text-decoration: line-through"
                        >{{ product.fakePrice | currency : "" }}</small
                      >
                    </div>
                    <div class="row">
                      <small style="font-size: 16px; color: #8f8f8f">{{
                        product.price | currency : ""
                      }}</small>
                    </div>
                  </div>
                  <div
                    class="col-5"
                    (click)="unlikeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="M440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q84 0 153 59t69 160q0 14-2 29.5t-6 31.5h-85q5-18 8-34t3-30q0-75-50-105.5T620-760q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Zm160-280v-80h320v80H600Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-5"
                    (click)="likeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="!product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-12 mt-3"
                    (click)="onView(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    VER PRODUCTO
                  </div>
                  <div
                    class="col-12 mt-3 button-style-card"
                    (click)="addToCar(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    <img
                      style="width: 20px; margin-right: 15px"
                      src="assets/icons/compras.png"
                      alt="compras"
                    />
                    AÑADIR AL CARRITO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div
            class="col-md-4 col-lg-3"
            *ngFor="let product of listProducts[3]?.slice(0, 4); let i = index"
          >
            <div class="card">
              <div class="card-body">
                <div
                  style="position: relative; cursor: pointer"
                  (click)="onView(product)"
                >
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal.offer }}</small>
                  </div>
                  <img
                    class="w-100"
                    src="{{ product?.image[0] }}"
                    alt="Card image cap"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small
                    >
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-5 d-block">
                    <div class="row" *ngIf="product.deal?.active">
                      <small
                        style="color: #8f8f8f; text-decoration: line-through"
                        >{{ product.fakePrice | currency : "" }}</small
                      >
                    </div>
                    <div class="row">
                      <small style="font-size: 16px; color: #8f8f8f">{{
                        product.price | currency : ""
                      }}</small>
                    </div>
                  </div>
                  <div
                    class="col-5"
                    (click)="unlikeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="M440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q84 0 153 59t69 160q0 14-2 29.5t-6 31.5h-85q5-18 8-34t3-30q0-75-50-105.5T620-760q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Zm160-280v-80h320v80H600Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-5"
                    (click)="likeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="!product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-12 mt-3"
                    (click)="onView(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    VER PRODUCTO
                  </div>
                  <div
                    class="col-12 mt-3 button-style-card"
                    (click)="addToCar(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    <img
                      style="width: 20px; margin-right: 15px"
                      src="assets/icons/compras.png"
                      alt="compras"
                    />
                    AÑADIR AL CARRITO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>

<div class="container h-100" *ngIf="showMid">
  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-ride="carousel"
  >
    <div class="carousel-inner mb-4">
      <div class="carousel-item active">
        <div class="row">
          <div
            class="col-6"
            *ngFor="let product of listProducts[0]?.slice(0, 2); let i = index"
          >
            <div class="card">
              <div class="card-body">
                <div
                  style="position: relative; cursor: pointer"
                  (click)="onView(product)"
                >
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal.offer }}</small>
                  </div>
                  <img
                    class="w-100"
                    src="{{ product?.image[0] }}"
                    alt="Card image cap"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small
                    >
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-5 d-block">
                    <div class="row" *ngIf="product.deal?.active">
                      <small
                        style="color: #8f8f8f; text-decoration: line-through"
                        >{{ product.fakePrice | currency : "" }}</small
                      >
                    </div>
                    <div class="row">
                      <small style="font-size: 16px; color: #8f8f8f">{{
                        product.price | currency : ""
                      }}</small>
                    </div>
                  </div>
                  <div
                    class="col-5"
                    (click)="unlikeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="M440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q84 0 153 59t69 160q0 14-2 29.5t-6 31.5h-85q5-18 8-34t3-30q0-75-50-105.5T620-760q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Zm160-280v-80h320v80H600Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-5"
                    (click)="likeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="!product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-12 mt-3"
                    (click)="onView(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    VER PRODUCTO
                  </div>
                  <div
                    class="col-12 mt-3 button-style-card"
                    (click)="addToCar(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    <img
                      style="width: 20px; margin-right: 15px"
                      src="assets/icons/compras.png"
                      alt="compras"
                    />
                    AÑADIR AL CARRITO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div
            class="col-6"
            *ngFor="let product of listProducts[1]?.slice(0, 2); let i = index"
          >
            <div class="card">
              <div class="card-body">
                <div
                  style="position: relative; cursor: pointer"
                  (click)="onView(product)"
                >
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal.offer }}</small>
                  </div>
                  <img
                    class="w-100"
                    src="{{ product?.image[0] }}"
                    alt="Card image cap"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small
                    >
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-5 d-block">
                    <div class="row" *ngIf="product.deal?.active">
                      <small
                        style="color: #8f8f8f; text-decoration: line-through"
                        >{{ product.fakePrice | currency : "" }}</small
                      >
                    </div>
                    <div class="row">
                      <small style="font-size: 16px; color: #8f8f8f">{{
                        product.price | currency : ""
                      }}</small>
                    </div>
                  </div>
                  <div
                    class="col-5"
                    (click)="unlikeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="M440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q84 0 153 59t69 160q0 14-2 29.5t-6 31.5h-85q5-18 8-34t3-30q0-75-50-105.5T620-760q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Zm160-280v-80h320v80H600Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-5"
                    (click)="likeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="!product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-12 mt-3"
                    (click)="onView(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    VER PRODUCTO
                  </div>
                  <div
                    class="col-12 mt-3 button-style-card"
                    (click)="addToCar(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    <img
                      style="width: 20px; margin-right: 15px"
                      src="assets/icons/compras.png"
                      alt="compras"
                    />
                    AÑADIR AL CARRITO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div
            class="col-6"
            *ngFor="let product of listProducts[2]?.slice(0, 2); let i = index"
          >
            <div class="card">
              <div class="card-body">
                <div
                  style="position: relative; cursor: pointer"
                  (click)="onView(product)"
                >
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal.offer }}</small>
                  </div>
                  <img
                    class="w-100"
                    src="{{ product?.image[0] }}"
                    alt="Card image cap"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small
                    >
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-5 d-block">
                    <div class="row" *ngIf="product.deal?.active">
                      <small
                        style="color: #8f8f8f; text-decoration: line-through"
                        >{{ product.fakePrice | currency : "" }}</small
                      >
                    </div>
                    <div class="row">
                      <small style="font-size: 16px; color: #8f8f8f">{{
                        product.price | currency : ""
                      }}</small>
                    </div>
                  </div>
                  <div
                    class="col-5"
                    (click)="unlikeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="M440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q84 0 153 59t69 160q0 14-2 29.5t-6 31.5h-85q5-18 8-34t3-30q0-75-50-105.5T620-760q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Zm160-280v-80h320v80H600Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-5"
                    (click)="likeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="!product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-12 mt-3"
                    (click)="onView(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    VER PRODUCTO
                  </div>
                  <div
                    class="col-12 mt-3 button-style-card"
                    (click)="addToCar(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    <img
                      style="width: 20px; margin-right: 15px"
                      src="assets/icons/compras.png"
                      alt="compras"
                    />
                    AÑADIR AL CARRITO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div
            class="col-6"
            *ngFor="let product of listProducts[3]?.slice(0, 2); let i = index"
          >
            <div class="card">
              <div class="card-body">
                <div
                  style="position: relative; cursor: pointer"
                  (click)="onView(product)"
                >
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal.offer }}</small>
                  </div>
                  <img
                    class="w-100"
                    src="{{ product?.image[0] }}"
                    alt="Card image cap"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small
                    >
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-5 d-block">
                    <div class="row" *ngIf="product.deal?.active">
                      <small
                        style="color: #8f8f8f; text-decoration: line-through"
                        >{{ product.fakePrice | currency : "" }}</small
                      >
                    </div>
                    <div class="row">
                      <small style="font-size: 16px; color: #8f8f8f">{{
                        product.price | currency : ""
                      }}</small>
                    </div>
                  </div>
                  <div
                    class="col-5"
                    (click)="unlikeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="M440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q84 0 153 59t69 160q0 14-2 29.5t-6 31.5h-85q5-18 8-34t3-30q0-75-50-105.5T620-760q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Zm160-280v-80h320v80H600Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-5"
                    (click)="likeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="!product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-12 mt-3"
                    (click)="onView(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    VER PRODUCTO
                  </div>
                  <div
                    class="col-12 mt-3 button-style-card"
                    (click)="addToCar(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    <img
                      style="width: 20px; margin-right: 15px"
                      src="assets/icons/compras.png"
                      alt="compras"
                    />
                    AÑADIR AL CARRITO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>

<div class="container h-100" *ngIf="showSm">
  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-ride="carousel"
  >
    <div class="carousel-inner mb-4">
      <div class="carousel-item active">
        <div class="row">
          <div
            class="col-12"
            *ngFor="let product of listProducts[0]?.slice(0, 1); let i = index"
          >
            <div class="card">
              <div class="card-body">
                <div
                  style="position: relative; cursor: pointer"
                  (click)="onView(product)"
                >
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal.offer }}</small>
                  </div>
                  <img
                    class="w-100"
                    src="{{ product?.image[0] }}"
                    alt="Card image cap"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small
                    >
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-5 d-block">
                    <div class="row" *ngIf="product.deal?.active">
                      <small
                        style="color: #8f8f8f; text-decoration: line-through"
                        >{{ product.fakePrice | currency : "" }}</small
                      >
                    </div>
                    <div class="row">
                      <small style="font-size: 16px; color: #8f8f8f">{{
                        product.price | currency : ""
                      }}</small>
                    </div>
                  </div>
                  <div
                    class="col-5"
                    (click)="unlikeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="M440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q84 0 153 59t69 160q0 14-2 29.5t-6 31.5h-85q5-18 8-34t3-30q0-75-50-105.5T620-760q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Zm160-280v-80h320v80H600Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-5"
                    (click)="likeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="!product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-12 mt-3"
                    (click)="onView(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    VER PRODUCTO
                  </div>
                  <div
                    class="col-12 mt-3 button-style-card"
                    (click)="addToCar(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    <img
                      style="width: 20px; margin-right: 15px"
                      src="assets/icons/compras.png"
                      alt="compras"
                    />
                    AÑADIR AL CARRITO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div
            class="col-12"
            *ngFor="let product of listProducts[1]?.slice(0, 1); let i = index"
          >
            <div class="card">
              <div class="card-body">
                <div
                  style="position: relative; cursor: pointer"
                  (click)="onView(product)"
                >
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal.offer }}</small>
                  </div>
                  <img
                    class="w-100"
                    src="{{ product?.image[0] }}"
                    alt="Card image cap"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small
                    >
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-5 d-block">
                    <div class="row" *ngIf="product.deal?.active">
                      <small
                        style="color: #8f8f8f; text-decoration: line-through"
                        >{{ product.fakePrice | currency : "" }}</small
                      >
                    </div>
                    <div class="row">
                      <small style="font-size: 16px; color: #8f8f8f">{{
                        product.price | currency : ""
                      }}</small>
                    </div>
                  </div>
                  <div
                    class="col-5"
                    (click)="unlikeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="M440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q84 0 153 59t69 160q0 14-2 29.5t-6 31.5h-85q5-18 8-34t3-30q0-75-50-105.5T620-760q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Zm160-280v-80h320v80H600Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-5"
                    (click)="likeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="!product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-12 mt-3"
                    (click)="onView(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    VER PRODUCTO
                  </div>
                  <div
                    class="col-12 mt-3 button-style-card"
                    (click)="addToCar(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    <img
                      style="width: 20px; margin-right: 15px"
                      src="assets/icons/compras.png"
                      alt="compras"
                    />
                    AÑADIR AL CARRITO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div
            class="col-12"
            *ngFor="let product of listProducts[2]?.slice(0, 1); let i = index"
          >
            <div class="card">
              <div class="card-body">
                <div
                  style="position: relative; cursor: pointer"
                  (click)="onView(product)"
                >
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal.offer }}</small>
                  </div>
                  <img
                    class="w-100"
                    src="{{ product?.image[0] }}"
                    alt="Card image cap"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small
                    >
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-5 d-block">
                    <div class="row" *ngIf="product.deal?.active">
                      <small
                        style="color: #8f8f8f; text-decoration: line-through"
                        >{{ product.fakePrice | currency : "" }}</small
                      >
                    </div>
                    <div class="row">
                      <small style="font-size: 16px; color: #8f8f8f">{{
                        product.price | currency : ""
                      }}</small>
                    </div>
                  </div>
                  <div
                    class="col-5"
                    (click)="unlikeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="M440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q84 0 153 59t69 160q0 14-2 29.5t-6 31.5h-85q5-18 8-34t3-30q0-75-50-105.5T620-760q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Zm160-280v-80h320v80H600Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-5"
                    (click)="likeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="!product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-12 mt-3"
                    (click)="onView(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    VER PRODUCTO
                  </div>
                  <div
                    class="col-12 mt-3 button-style-card"
                    (click)="addToCar(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    <img
                      style="width: 20px; margin-right: 15px"
                      src="assets/icons/compras.png"
                      alt="compras"
                    />
                    AÑADIR AL CARRITO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="row">
          <div
            class="col-12"
            *ngFor="let product of listProducts[3]?.slice(0, 1); let i = index"
          >
            <div class="card">
              <div class="card-body">
                <div
                  style="position: relative; cursor: pointer"
                  (click)="onView(product)"
                >
                  <div *ngIf="product.deal?.active" class="offer">
                    <small>-{{ product.deal.offer }}</small>
                  </div>
                  <img
                    class="w-100"
                    src="{{ product?.image[0] }}"
                    alt="Card image cap"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="font-size: 1.5rv">
                      <strong>{{ product.description }}</strong></small
                    >
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-5 d-block">
                    <div class="row" *ngIf="product.deal?.active">
                      <small
                        style="color: #8f8f8f; text-decoration: line-through"
                        >{{ product.fakePrice | currency : "" }}</small
                      >
                    </div>
                    <div class="row">
                      <small style="font-size: 16px; color: #8f8f8f">{{
                        product.price | currency : ""
                      }}</small>
                    </div>
                  </div>
                  <div
                    class="col-5"
                    (click)="unlikeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="M440-501Zm0 381L313-234q-72-65-123.5-116t-85-96q-33.5-45-49-87T40-621q0-94 63-156.5T260-840q52 0 99 22t81 62q34-40 81-62t99-22q84 0 153 59t69 160q0 14-2 29.5t-6 31.5h-85q5-18 8-34t3-30q0-75-50-105.5T620-760q-51 0-88 27.5T463-660h-46q-31-45-70.5-72.5T260-760q-57 0-98.5 39.5T120-621q0 33 14 67t50 78.5q36 44.5 98 104T440-228q26-23 61-53t56-50l9 9 19.5 19.5L605-283l9 9q-22 20-56 49.5T498-172l-58 52Zm160-280v-80h320v80H600Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-5"
                    (click)="likeProduct(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                    *ngIf="!product.liked"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#000"
                    >
                      <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="col-12 mt-3"
                    (click)="onView(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    VER PRODUCTO
                  </div>
                  <div
                    class="col-12 mt-3 button-style-card"
                    (click)="addToCar(product)"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    "
                  >
                    <img
                      style="width: 20px; margin-right: 15px"
                      src="assets/icons/compras.png"
                      alt="compras"
                    />
                    AÑADIR AL CARRITO
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
